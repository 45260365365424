import { Injectable } from '@angular/core';
import * as __fs from 'fs';
import * as __os from 'os';
import * as __process from 'process';
import * as __electron from 'electron';
import * as __net from 'net';
import * as __path from 'path';
import * as __ffi from 'ffi';
import * as __refStruct from 'ref-struct';
import * as __ref from 'ref';
import * as __http from 'http';
import * as __remote from '@electron/remote';

@Injectable()
export class NodeService {

    private _http: any;
    private _fs: any;
    private _os: any;
    private _process: any;
    private _electron: any;
    private _electronIpcRenderer: Electron.IpcRenderer;
    private _remote: any;
    private _net: any;
    private _path: any;
    private _ffi: any;
    private _refStruct: any;
    private _ref: any;

    private requirePrefix;

    public constructor() {
        if (this.process.execPath.indexOf('node_modules') >= 0) {
            this.requirePrefix = this.process.execPath.split('node_modules')[0] + 'node_modules\\';
        } else {
            this.requirePrefix = this.path.dirname(this.process.execPath) + '\\resources\\app\\node_modules\\';
        }
    }

    public isAvailable() {
        return (window && (window as any).process !== undefined);
    }


    public get electron() {
        if (!this._electron) {
            this._electron = this.require('electron');
        }
        (__electron as any) = this._electron;
        return __electron;
    }

    public get http() {
        if (!this._http) {
            this._http = this.require('http');
        }
        (__http as any) = this._http;
        return __http;
    }

    public get fs() {
        if (!this._fs) {
            this._fs = this.require('fs');
        }
        (__fs as any) = this._fs;
        return __fs;
    }

    public get os() {
        if (!this._os) {
            this._os = this.require('os');
        }
        (__os as any) = this._os;
        return __os;
    }

    public get process() {
        if (!this._process) {
            this._process = this.require('process');
        }
        (__process as any) = this._process;
        return __process;
    }

    public get ipcRenderer() {
        if (!this._electronIpcRenderer) {
            const { ipcRenderer } = this.require('electron');
            this._electronIpcRenderer = ipcRenderer;
        }
        return this._electronIpcRenderer;
    }

    public get remote() {
        if (!this._remote) {
            const remote = this.requireNonNativePkg('@electron/remote');
            this._remote = remote;
        }
        (__remote as any) = this._remote;
        return __remote;
    }

    public get net() {
        if (!this._net) {
            this._net = this.require('net');
        }
        (__net as any) = this._net;
        return __net;
    }

    public get path() {
        if (!this._path) {
            this._path = this.require('path');
        }
        (__path as any) = this._path;
        return this._path;
    }

    public get ffi() {
        if (!this._ffi) {
            this._ffi = this.requireNonNativePkg('ffi-napi');
        }
        (__ffi as any) = this._ffi;
        return __ffi;
    }

    public get refStruct() {
        if (!this._refStruct) {
            this._refStruct = this.requireNonNativePkg('ref-struct-napi');
        }
        (__refStruct as any) = this._refStruct;
        return __refStruct;
    }

    public get ref() {
        if (!this._ref) {
            this._ref = this.requireNonNativePkg('ref-napi');
        }
        (__ref as any) = this._ref;
        return __ref;
    }

    private require(pkg: string): any {
        return (window as any).require(pkg);
    }

    private requireNonNativePkg(pkg: string): any {
        return (window as any).require(this.requirePrefix + pkg);
    }
}
