<div class="topbar-wrapper">
    <div class="topbar1">
        <div class="field field-gold" [style.borderTopColor]="getColorCode(player1)"><span
                *ngIf="player1.ressources">{{player1.ressources.gold}}</span></div>
        <div class="field field-lumber" [style.borderTopColor]="getColorCode(player1)"><span
                *ngIf="player1.ressources">{{player1.ressources.lumber}}</span></div>
        <div class="field field-food" *ngIf="!player1.ressources" [style.borderTopColor]="getColorCode(player1)"></div>
        <div class="field field-food no-upkeep-color" *ngIf="player1.ressources && player1.ressources.food <= 50"
            [style.borderTopColor]="getColorCode(player1)">
            <span>{{player1.ressources.food}}</span>/{{player1.ressources.food_cap}}
        </div>
        <div class="field field-food low-upkeep-color" [style.borderTopColor]="getColorCode(player1)"
            *ngIf="player1.ressources && player1.ressources.food > 50 && player1.ressources.food <= 80 ">
            <span>{{player1.ressources.food}}</span>/{{player1.ressources.food_cap}}
        </div>
        <div class="field field-food high-upkeep-color" *ngIf="player1.ressources && player1.ressources.food > 80"
            [style.borderTopColor]="getColorCode(player1)">
            <span>{{player1.ressources.food}}</span>/{{player1.ressources.food_cap}}
        </div>
        <div class="field field-worker-food" [style.borderTopColor]="getColorCode(player1)"> <span
                *ngIf="player1.ressources">{{player1.ressources.worker_food}}</span>
        </div>
        <div class="field field-army-food" [style.borderTopColor]="getColorCode(player1)"><span
                *ngIf="player1.ressources">{{player1.ressources.food - player1.ressources.worker_food}}</span></div>
        <div *ngIf="Boolean(settings.showRacesOnlyTextual)" class="field field-race field-race-textonly" [style.borderTopColor]="getColorCode(player1)">
            <div class="player-color" [style.backgroundColor]="getColorCode(player1)">
                <div class="player-color-gradient">{{raceShortNames.get(player1.race)}}</div>
            </div>
        </div>
        <div *ngIf="!Boolean(settings.showRacesOnlyTextual)" class="field field-race field-race-{{player1.race}}" [style.borderTopColor]="getColorCode(player1)">
            <div class="player-color" [style.backgroundColor]="getColorCode(player1)">
                <div class="player-color-gradient"></div>
            </div>
        </div>
        <div class="field field-time">
            <span>{{formattedGameTime}}</span>
            <div class="daynightcycle-wrapper">
                <div *ngIf="GetCurrentDayNightCylce() % 2 == 0">
                    <div class="daynightcycle daynightcycle-bg daynightcycle-{{GetLastDayNightCylce()}}"></div>
                    <div class="daynightcycle daynightcycle-front daynightcycle-{{GetCurrentDayNightCylce()}}"></div>
                </div>
                <div *ngIf="GetCurrentDayNightCylce() % 2 == 1">
                    <div class="daynightcycle daynightcycle-bg daynightcycle-{{GetLastDayNightCylce()}}"></div>
                    <div class="daynightcycle daynightcycle-front daynightcycle-{{GetCurrentDayNightCylce()}}"></div>
                </div>
            </div>
        </div>
        <div *ngIf="Boolean(settings.showRacesOnlyTextual)" class="field field-race field-race-textonly revert-bg" [style.borderTopColor]="getColorCode(player2)">
            <div class="player-color" [style.backgroundColor]="getColorCode(player2)">
                <div class="player-color-gradient">{{raceShortNames.get(player2.race)}}</div>
            </div>
        </div>
        <div *ngIf="!Boolean(settings.showRacesOnlyTextual)" class="field field-race field-race-{{player2.race}} revert-bg"
            [style.borderTopColor]="getColorCode(player2)">
            <div class="player-color" [style.backgroundColor]="getColorCode(player2)">
                <div class="player-color-gradient"></div>
            </div>
        </div>
        <div class="field field-gold" [style.borderTopColor]="getColorCode(player2)"><span
                *ngIf="player2.ressources">{{player2.ressources.gold}}</span></div>
        <div class="field field-lumber" [style.borderTopColor]="getColorCode(player2)"><span
                *ngIf="player2.ressources">{{player2.ressources.lumber}}</span></div>
        <div class="field field-food" *ngIf="!player2.ressources" [style.borderTopColor]="getColorCode(player2)"></div>
        <div class="field field-food no-upkeep-color" *ngIf="player2.ressources && player2.ressources.food <= 50"
            [style.borderTopColor]="getColorCode(player2)">
            <span>{{player2.ressources.food}}</span>/{{player2.ressources.food_cap}}
        </div>
        <div class="field field-food low-upkeep-color" [style.borderTopColor]="getColorCode(player2)"
            *ngIf="player2.ressources && player2.ressources.food > 50 && player2.ressources.food <= 80 ">
            <span>{{player2.ressources.food}}</span>/{{player2.ressources.food_cap}}
        </div>
        <div class="field field-food high-upkeep-color" *ngIf="player2.ressources && player2.ressources.food > 80"
            [style.borderTopColor]="getColorCode(player2)">
            <span>{{player2.ressources.food}}</span>/{{player2.ressources.food_cap}}
        </div>
        <div class="field field-worker-food" [style.borderTopColor]="getColorCode(player2)"><span
                *ngIf="player2.ressources">{{player2.ressources.worker_food}}</span>
        </div>
        <div class="field field-army-food" [style.borderTopColor]="getColorCode(player2)"><span
                *ngIf="player2.ressources">{{player2.ressources.food - player2.ressources.worker_food }}</span></div>
    </div>

    <div class="topbar2">
        <div>
            <div class="player-name player-name-left">
                <div class="player-country"
                    *ngIf="player1.mainAccount && player1.mainAccount.country && player1.mainAccount.country != 'tw'"
                    [style.backgroundImage]="'url(/assets/img/flags/'+ player1.mainAccount.country +'.png)'">
                </div>
                {{player1.name}}
            </div>
        </div>
        <div> </div>
        <div
            [class]="(Boolean(settings.matchscoreEnabled) && !game.isReplay || Boolean(settings.matchscoreEnabledInReplay) && game.isReplay) ? 'show-matchscore' : 'hide-matchscore'">
            <div class="score-count left-score-count">
                {{this.misc.matchscoreWins}}
            </div>
            <div class="content">
                <div [class]="Boolean(settings.headlineTopIsMainHeadline) ? 'headline' : 'subline'">
                    {{settings.headlineTop}}
                </div>
                <div [class]="!Boolean(settings.headlineTopIsMainHeadline) ? 'headline' : 'subline'">
                    {{settings.headlineBot}}
                </div>
            </div>
            <div class="score-count right-score-count">
                {{this.misc.matchscoreLosses}}
            </div>
        </div>
        <div> </div>
        <div>
            <div class="player-name player-name-right">
                <div class="player-country"
                    *ngIf="player2.mainAccount && player2.mainAccount.country && player2.mainAccount.country != 'tw'"
                    [style.backgroundImage]="'url(/assets/img/flags/'+ player2.mainAccount.country +'.png)'">
                </div>
                {{player2.name}}
            </div>
        </div>

    </div>

</div>