export const w3Collection = {
    Hamg: {
        icon: 'btnheroarchmage.png'
    },
    Hblm: {
        icon: 'btnherobloodelfprince.png'
    },
    Hmkg: {
        icon: 'btnheromountainking.png'
    },
    Hpal: {
        icon: 'btnheropaladin.png'
    },
    hbot: {
        icon: 'btnhumantransport.png'
    },
    hbsh: {
        icon: 'btnhumanbattleship.png'
    },
    hdes: {
        icon: 'btnhumandestroyer.png'
    },
    hdhw: {
        icon: 'btndragonhawk.png'
    },
    hfoo: {
        icon: 'btnfootman.png'
    },
    hgry: {
        icon: 'btngryphonrider.png'
    },
    hgyr: {
        icon: 'btnflyingmachine.png'
    },
    hkni: {
        icon: 'btnknight.png'
    },
    hmil: {
        icon: 'btnmilitia.png'
    },
    hmpr: {
        icon: 'btnpriest.png'
    },
    hmtm: {
        icon: 'btnmortarteam.png'
    },
    hmtt: {
        icon: 'btnseigeengine.png'
    },
    hpea: {
        icon: 'btnpeasant.png'
    },
    hphx: {
        icon: 'btnmarkoffire.png'
    },
    hpxe: {
        icon: 'btnphoenixegg.png'
    },
    hrif: {
        icon: 'btnrifleman.png'
    },
    hrtt: {
        icon: 'btnseigeenginewithmissles.png'
    },
    hsor: {
        icon: 'btnsorceress.png'
    },
    hspt: {
        icon: 'btnspellbreaker.png'
    },
    hwat: {
        icon: 'btnsummonwaterelemental.png'
    },
    hwt2: {
        icon: 'btnsummonwaterelemental.png'
    },
    hwt3: {
        icon: 'btnsummonwaterelemental.png'
    },
    halt: {
        icon: 'btnaltarofkings.png'
    },
    harm: {
        icon: 'btnworkshop.png'
    },
    hars: {
        icon: 'btnarcanesanctum.png'
    },
    hatw: {
        icon: 'btnhumanarcanetower.png'
    },
    hbar: {
        icon: 'btnhumanbarracks.png'
    },
    hbla: {
        icon: 'btnblacksmith.png'
    },
    hcas: {
        icon: 'btncastle.png'
    },
    hctw: {
        icon: 'btncannontower.png'
    },
    hgra: {
        icon: 'btngryphonaviary.png'
    },
    hgtw: {
        icon: 'btnguardtower.png'
    },
    hhou: {
        icon: 'btnfarm.png'
    },
    hkee: {
        icon: 'btnkeep.png'
    },
    hlum: {
        icon: 'btnhumanlumbermill.png'
    },
    hshy: {
        icon: 'btnhumanshipyard.png'
    },
    htow: {
        icon: 'btntownhall.png'
    },
    hvlt: {
        icon: 'btnarcanevault.png'
    },
    hwtw: {
        icon: 'btnhumanwatchtower.png'
    },
    Obla: {
        icon: 'btnheroblademaster.png'
    },
    Ofar: {
        icon: 'btnherofarseer.png'
    },
    Oshd: {
        icon: 'btnshadowhunter.png'
    },
    Otch: {
        icon: 'btnherotaurenchieftain.png'
    },
    nwad: {
        icon: 'btnsentryward.png'
    },
    obot: {
        icon: 'btnorcishtransport.png'
    },
    ocat: {
        icon: 'btndemolisher.png'
    },
    odes: {
        icon: 'btnorcdestroyer.png'
    },
    odoc: {
        icon: 'btnwitchdoctor.png'
    },
    oeye: {
        icon: 'btnsentryward.png'
    },
    ogru: {
        icon: 'btngrunt.png'
    },
    ohun: {
        icon: 'btnheadhunter.png'
    },
    ohwd: {
        icon: 'btnhealingward.png'
    },
    okod: {
        icon: 'btnkodobeast.png'
    },
    opeo: {
        icon: 'btnpeon.png'
    },
    orai: {
        icon: 'btnraider.png'
    },
    oshm: {
        icon: 'btnshaman.png'
    },
    osp1: {
        icon: 'btnserpentward.png'
    },
    osp2: {
        icon: 'btnserpentward.png'
    },
    osp3: {
        icon: 'btnserpentward.png'
    },
    osp4: {
        icon: 'btnserpentward.png'
    },
    ospm: {
        icon: 'btnspiritwalker.png'
    },
    ospw: {
        icon: 'btnspiritwalker.png'
    },
    osw1: {
        icon: 'btnspiritwolf.png'
    },
    osw2: {
        icon: 'btnspiritwolf.png'
    },
    osw3: {
        icon: 'btnspiritwolf.png'
    },
    otau: {
        icon: 'btntauren.png'
    },
    otbk: {
        icon: 'btnheadhunterberserker.png'
    },
    otbr: {
        icon: 'btntrollbatrider.png'
    },
    otot: {
        icon: 'btnstasistrap.png'
    },
    owyv: {
        icon: 'btnwyvernrider.png'
    },
    oalt: {
        icon: 'btnaltarofstorms.png'
    },
    obar: {
        icon: 'btnbarracks.png'
    },
    obea: {
        icon: 'btnbeastiary.png'
    },
    ofor: {
        icon: 'btnforge.png'
    },
    ofrt: {
        icon: 'btnfortress.png'
    },
    ogre: {
        icon: 'btngreathall.png'
    },
    oshy: {
        icon: 'btngoblinshipyard.png'
    },
    osld: {
        icon: 'btnspiritlodge.png'
    },
    ostr: {
        icon: 'btnstronghold.png'
    },
    otrb: {
        icon: 'btntrollburrow.png'
    },
    otto: {
        icon: 'btntaurentotem.png'
    },
    ovln: {
        icon: 'btnvoodoolounge.png'
    },
    owtw: {
        icon: 'btnorctower.png'
    },
    Edem: {
        icon: 'btnherodemonhunter.png'
    },
    Edmm: {
        icon: 'btnmetamorphosis.png'
    },
    Ekee: {
        icon: 'btnkeeperofthegrove.png'
    },
    Emoo: {
        icon: 'btnpriestessofthemoon.png'
    },
    Ewar: {
        icon: 'btnherowarden.png'
    },
    earc: {
        icon: 'btnarcher.png'
    },
    ebal: {
        icon: 'btnglaivethrower.png'
    },
    ebsh: {
        icon: 'btnnightelfbattlecruiser.png'
    },
    echm: {
        icon: 'btnchimaera.png'
    },
    edcm: {
        icon: 'btnbearform.png'
    },
    edes: {
        icon: 'btnnightelfdestroyer.png'
    },
    edoc: {
        icon: 'btndruidoftheclaw.png'
    },
    edot: {
        icon: 'btndruidofthetalon.png'
    },
    edry: {
        icon: 'btndryad.png'
    },
    edtm: {
        icon: 'btnravenform.png'
    },
    efdr: {
        icon: 'btnfaeriedragon.png'
    },
    efon: {
        icon: 'btnent.png'
    },
    ehip: {
        icon: 'btnhippogriff.png'
    },
    ehpr: {
        icon: 'btnhippogriffrider.png'
    },
    emtg: {
        icon: 'btnmountaingiant.png'
    },
    esen: {
        icon: 'btnhuntress.png'
    },
    espv: {
        icon: 'btnspiritofvengeance.png'
    },
    even: {
        icon: 'btnavengingwatcher.png'
    },
    ewsp: {
        icon: 'btnwisp.png'
    },
    eaoe: {
        icon: 'btnancientoflore.png'
    },
    eaom: {
        icon: 'btnancientoftheearth.png'
    },
    eaow: {
        icon: 'btnancientofthemoon.png'
    },
    eate: {
        icon: 'btnaltarofelders.png'
    },
    eden: {
        icon: 'btnancientofwonders.png'
    },
    edob: {
        icon: 'btnhuntershall.png'
    },
    edos: {
        icon: 'btnchimaeraroost.png'
    },
    egol: {
        icon: 'btngoldmine.png'
    },
    emow: {
        icon: 'btnmoonwell.png'
    },
    eshy: {
        icon: 'btnnightelfshipyard.png'
    },
    etoa: {
        icon: 'btntreeofages.png'
    },
    etoe: {
        icon: 'btntreeofeternity.png'
    },
    etol: {
        icon: 'btntreeoflife.png'
    },
    etrp: {
        icon: 'btntreant.png'
    },
    Ucrl: {
        icon: 'btnherocryptlord.png'
    },
    Udea: {
        icon: 'btnherodeathknight.png'
    },
    Udre: {
        icon: 'btnherodreadlord.png'
    },
    Ulic: {
        icon: 'btnlichversion2.png'
    },
    uabo: {
        icon: 'btnabomination.png'
    },
    uaco: {
        icon: 'btnacolyte.png'
    },
    uban: {
        icon: 'btnbanshee.png'
    },
    ubsp: {
        icon: 'btndestroyer.png'
    },
    ucrm: {
        icon: 'btncryptfiend.png'
    },
    ucry: {
        icon: 'btncryptfiend.png'
    },
    ucs1: {
        icon: 'btncarrionscarabs.png'
    },
    ucs2: {
        icon: 'btncarrionscarabs.png'
    },
    ucs3: {
        icon: 'btncarrionscarabs.png'
    },
    ucsB: {
        icon: 'btncarrionscarabs.png'
    },
    ucsC: {
        icon: 'btncarrionscarabs.png'
    },
    ufro: {
        icon: 'btnfrostwyrm.png'
    },
    ugar: {
        icon: 'btngargoyle.png'
    },
    ugho: {
        icon: 'btnghoul.png'
    },
    ugrm: {
        icon: 'btnstoneform.png'
    },
    uloc: {
        icon: 'btnlocustswarm.png'
    },
    umtw: {
        icon: 'btnmeatwagon.png'
    },
    unec: {
        icon: 'btnnecromancer.png'
    },
    uobs: {
        icon: 'btnobsidianstatue.png'
    },
    uplg: {
        icon: 'pasbtnplaguecloud.png'
    },
    ushd: {
        icon: 'btnshade.png'
    },
    uske: {
        icon: 'btnskeletonwarrior.png'
    },
    uskm: {
        icon: 'btnskeletonmage.png'
    },
    uubs: {
        icon: 'btnundeadbattleship.png'
    },
    uaod: {
        icon: 'btnaltarofdarkness.png'
    },
    ubon: {
        icon: 'btnboneyard.png'
    },
    ugol: {
        icon: 'btngoldmine.png'
    },
    ugrv: {
        icon: 'btngraveyard.png'
    },
    unp1: {
        icon: 'btnhallofthedead.png'
    },
    unp2: {
        icon: 'btnblackcitadel.png'
    },
    unpl: {
        icon: 'btnnecropolis.png'
    },
    usap: {
        icon: 'btnsacrificialpit.png'
    },
    usep: {
        icon: 'btncrypt.png'
    },
    ushp: {
        icon: 'btnundeadshipyard.png'
    },
    uslh: {
        icon: 'btnslaughterhouse.png'
    },
    utod: {
        icon: 'btntempleofthedamned.png'
    },
    utom: {
        icon: 'btntombofrelics.png'
    },
    uzg1: {
        icon: 'btnzigguratupgrade.png'
    },
    uzg2: {
        icon: 'btnfrosttower.png'
    },
    uzig: {
        icon: 'btnziggurat.png'
    },
    Nbrn: {
        icon: 'btnbansheeranger.png'
    },
    Nbst: {
        icon: 'btnbeastmaster.png'
    },
    Nngs: {
        icon: 'btnnagaseawitch.png'
    },
    Npbm: {
        icon: 'btnpandarenbrewmaster.png'
    },
    Nalc: {
        icon: 'btnheroalchemist.png'
    },
    Nalm: {
        icon: 'btnheroalchemist.png'
    },
    Nal2: {
        icon: 'btnheroalchemist.png'
    },
    Nal3: {
        icon: 'btnheroalchemist.png'
    },
    Ntin: {
        icon: 'btnherotinker.png'
    },
    Nrob: {
        icon: 'btnherotinker.png'
    },
    ncgb: {
        icon: 'btnclockwerkgoblin.png'
    },
    ncg1: {
        icon: 'btnclockwerkgoblin.png'
    },
    ncg2: {
        icon: 'btnclockwerkgoblin.png'
    },
    ncg3: {
        icon: 'btnclockwerkgoblin.png'
    },
    nfac: {
        icon: 'btnpocketfactory.png'
    },
    nfa1: {
        icon: 'btnpocketfactory.png'
    },
    nfa2: {
        icon: 'btnpocketfactory.png'
    },
    Nplh: {
        icon: 'btnpitlord.png'
    },
    Nfir: {
        icon: 'btnheroavatarofflame.png'
    },
    nlv1: {
        icon: 'btnlavaspawn.png'
    },
    nlv2: {
        icon: 'btnlavaspawn.png'
    },
    nlv3: {
        icon: 'btnlavaspawn.png'
    },
    ndr1: {
        icon: 'btnskeletonwarrior.png'
    },
    ndr2: {
        icon: 'btnskeletonwarrior.png'
    },
    ndr3: {
        icon: 'btnskeletonwarrior.png'
    },
    ngz1: {
        icon: 'btngrizzlybear.png'
    },
    ngz2: {
        icon: 'btngrizzlybear.png'
    },
    ngz3: {
        icon: 'btngrizzlybear.png'
    },
    ngzc: {
        icon: 'btngrizzlybear.png'
    },
    ngzd: {
        icon: 'btngrizzlybear.png'
    },
    ngza: {
        icon: 'btngrizzlybear.png'
    },
    ngz4: {
        icon: 'btngrizzlybear.png'
    },
    npn1: {
        icon: 'btnfirebrewmaster.png'
    },
    npn2: {
        icon: 'btnstormbrewmaster.png'
    },
    npn3: {
        icon: 'btnearthbrewmaster.png'
    },
    npn4: {
        icon: 'btnfirebrewmaster.png'
    },
    npn5: {
        icon: 'btnstormbrewmaster.png'
    },
    npn6: {
        icon: 'btnearthbrewmaster.png'
    },
    nqb1: {
        icon: 'btnquillbeast.png'
    },
    nqb2: {
        icon: 'btnquillbeast.png'
    },
    nqb3: {
        icon: 'btnquillbeast.png'
    },
    nqb4: {
        icon: 'btnquillbeast.png'
    },
    nwe1: {
        icon: 'btnwareagle.png'
    },
    nwe2: {
        icon: 'btnwareagle.png'
    },
    nwe3: {
        icon: 'btnwareagle.png'
    },
    nadk: {
        icon: 'btnazuredragon.png'
    },
    nadr: {
        icon: 'btnazuredragon.png'
    },
    nadw: {
        icon: 'btnazuredragon.png'
    },
    nahy: {
        icon: 'btngreenhydra.png'
    },
    nanb: {
        icon: 'btnarachnathid.png'
    },
    nanm: {
        icon: 'btnarachnathid.png'
    },
    nanc: {
        icon: 'btnarachnathidgreen.png'
    },
    nane: {
        icon: 'btnarachnathid.png'
    },
    nano: {
        icon: 'btnarachnathidpurple.png'
    },
    nanw: {
        icon: 'btnarachnathidgreen.png'
    },
    narg: {
        icon: 'btnarmorgolem.png'
    },
    nass: {
        icon: 'btnbanditspearthrower.png'
    },
    nbal: {
        icon: 'btndoomguard.png'
    },
    nba2: {
        icon: 'btndoomguard.png'
    },
    nban: {
        icon: 'btnbandit.png'
    },
    nbda: {
        icon: 'btngreendragonspawn.png'
    },
    nbdk: {
        icon: 'btnblackdragon.png'
    },
    nbdm: {
        icon: 'btnbluedragonspawn.png'
    },
    nbdo: {
        icon: 'btnpurpledragonspawn.png'
    },
    nbdr: {
        icon: 'btnblackdragon.png'
    },
    nbds: {
        icon: 'btngreendragonspawn.png'
    },
    nbdw: {
        icon: 'btnbluedragonspawn.png'
    },
    nbld: {
        icon: 'btnbanditlord.png'
    },
    nbnb: {
        icon: 'btnarachnathid.png'
    },
    nbot: {
        icon: 'btntransport.png'
    },
    nbrg: {
        icon: 'btnbanditspearthrower.png'
    },
    nbwm: {
        icon: 'btnblackdragon.png'
    },
    nbzd: {
        icon: 'btnbronzedragon.png'
    },
    nbzk: {
        icon: 'btnbronzedragon.png'
    },
    nbzw: {
        icon: 'btnbronzedragon.png'
    },
    ncea: {
        icon: 'btncentaurarcher.png'
    },
    ncen: {
        icon: 'btncentaur.png'
    },
    ncer: {
        icon: 'btncentaur.png'
    },
    ncfs: {
        icon: 'btnmurgultidewarrior.png'
    },
    ncim: {
        icon: 'btncentaurarcher.png'
    },
    ncks: {
        icon: 'btncentaurkhan.png'
    },
    ncnk: {
        icon: 'btncentaurkhan.png'
    },
    ndqn: {
        icon: 'btndemoness.png'
    },
    ndqp: {
        icon: 'btnbluedemoness.png'
    },
    ndqs: {
        icon: 'btnbluedemoness.png'
    },
    ndqt: {
        icon: 'btndemoness.png'
    },
    ndqv: {
        icon: 'btnbluedemoness.png'
    },
    ndrv: {
        icon: 'btnrevenant.png'
    },
    ndtb: {
        icon: 'btndarktroll.png'
    },
    ndth: {
        icon: 'btndarktrollshadowpriest.png'
    },
    ndtp: {
        icon: 'btndarktrollshadowpriest.png'
    },
    ndtr: {
        icon: 'btndarktroll.png'
    },
    ndtt: {
        icon: 'btndarktrolltrapper.png'
    },
    ndtw: {
        icon: 'btndarktrolltrapper.png'
    },
    nehy: {
        icon: 'btngreenhydra.png'
    },
    nelb: {
        icon: 'btnsummonwaterelemental.png'
    },
    nele: {
        icon: 'btnsummonwaterelemental.png'
    },
    nenc: {
        icon: 'btncorruptedent.png'
    },
    nenf: {
        icon: 'btnbandit.png'
    },
    nenp: {
        icon: 'btncorruptedent.png'
    },
    nepl: {
        icon: 'btncorruptedent.png'
    },
    nerd: {
        icon: 'btneredarwarlockpurple.png'
    },
    ners: {
        icon: 'btneredarwarlockpurple.png'
    },
    nerw: {
        icon: 'btneredarwarlockpurple.png'
    },
    nfel: {
        icon: 'btnfelhound.png'
    },
    nfgb: {
        icon: 'btnfelguard.png'
    },
    nfgo: {
        icon: 'btnforgottenone.png'
    },
    nfgt: {
        icon: 'btntentacle.png'
    },
    nfgu: {
        icon: 'btnfelguard.png'
    },
    nfod: {
        icon: 'btnfacelessone.png'
    },
    nfor: {
        icon: 'btnfacelessone.png'
    },
    nfot: {
        icon: 'btnfacelessone.png'
    },
    nfov: {
        icon: 'btnfelguardblue.png'
    },
    nfpc: {
        icon: 'btnpolarfurbolg.png'
    },
    nfpe: {
        icon: 'btnpolarfurbolgshaman.png'
    },
    nfpl: {
        icon: 'btnpolarfurbolg.png'
    },
    nfps: {
        icon: 'btnpolarfurbolgshaman.png'
    },
    nfpt: {
        icon: 'btnpolarfurbolgtracker.png'
    },
    nfpu: {
        icon: 'btnpolarfurbolgelder.png'
    },
    nfra: {
        icon: 'btnfurbolgelder.png'
    },
    nfrb: {
        icon: 'btnfurbolgtracker.png'
    },
    nfre: {
        icon: 'btnfurbolgshaman.png'
    },
    nfrg: {
        icon: 'btnfurbolg.png'
    },
    nfrl: {
        icon: 'btnfurbolg.png'
    },
    nfrp: {
        icon: 'btnfurbolgpanda.png'
    },
    nfrs: {
        icon: 'btnfurbolgshaman.png'
    },
    nfsh: {
        icon: 'btnforesttrollshadowpriest.png'
    },
    nfsp: {
        icon: 'btnforesttrollshadowpriest.png'
    },
    nftb: {
        icon: 'btnforesttroll.png'
    },
    nftk: {
        icon: 'btnforesttroll.png'
    },
    nftr: {
        icon: 'btnforesttroll.png'
    },
    nftt: {
        icon: 'btnforesttrolltrapper.png'
    },
    ngdk: {
        icon: 'btngreendragon.png'
    },
    nggr: {
        icon: 'btnrockgolem.png'
    },
    ngh1: {
        icon: 'btnghost.png'
    },
    ngh2: {
        icon: 'btnghost.png'
    },
    ngir: {
        icon: 'btnjunkgolem.png'
    },
    nglm: {
        icon: 'btngoblinlandmine.png'
    },
    ngna: {
        icon: 'btngnollarcher.png'
    },
    ngnb: {
        icon: 'btngnoll.png'
    },
    ngno: {
        icon: 'btngnoll.png'
    },
    ngns: {
        icon: 'btngnollarcher.png'
    },
    ngnv: {
        icon: 'btngnollking.png'
    },
    ngnw: {
        icon: 'btngnollwarden.png'
    },
    ngrd: {
        icon: 'btngreendragon.png'
    },
    ngrk: {
        icon: 'btnrockgolem.png'
    },
    ngrw: {
        icon: 'btngreendragon.png'
    },
    ngsp: {
        icon: 'btngoblinsapper.png'
    },
    ngst: {
        icon: 'btnrockgolem.png'
    },
    nhar: {
        icon: 'btnharpy.png'
    },
    nhdc: {
        icon: 'btnacolyte.png'
    },
    nhfp: {
        icon: 'btnacolyte.png'
    },
    nhhr: {
        icon: 'btnacolyte.png'
    },
    nhrh: {
        icon: 'btnharpywitch.png'
    },
    nhrq: {
        icon: 'btnharpyqueen.png'
    },
    nhrr: {
        icon: 'btnharpy.png'
    },
    nhrw: {
        icon: 'btnharpywitch.png'
    },
    nhyc: {
        icon: 'btnseaturtlered.png'
    },
    nhyd: {
        icon: 'btngreenhydra.png'
    },
    nhyh: {
        icon: 'btngreenhydra.png'
    },
    nina: {
        icon: 'btninfernalflamecannon.png'
    },
    ninc: {
        icon: 'btninfernalcannon.png'
    },
    ninf: {
        icon: 'btninfernal.png'
    },
    ninm: {
        icon: 'btninfernalcannon.png'
    },
    nith: {
        icon: 'btnicetrollshaman.png'
    },
    nitp: {
        icon: 'btnicetrollshadowpriest.png'
    },
    nitr: {
        icon: 'btnicetroll.png'
    },
    nits: {
        icon: 'btnicetrollbeserker.png'
    },
    nitt: {
        icon: 'btnicetroll.png'
    },
    nitw: {
        icon: 'btnicetroll.png'
    },
    njgb: {
        icon: 'btnjunglebeast.png'
    },
    njga: {
        icon: 'btnjunglebeast.png'
    },
    njg1: {
        icon: 'btnjunglebeast.png'
    },
    nkob: {
        icon: 'btnkobold.png'
    },
    nkog: {
        icon: 'btnkoboldgeomancer.png'
    },
    nkol: {
        icon: 'btnkobold.png'
    },
    nkot: {
        icon: 'btnkobold.png'
    },
    nlds: {
        icon: 'btnlobstrokkgreen.png'
    },
    nlkl: {
        icon: 'btnlobstrokkblue.png'
    },
    nlpd: {
        icon: 'btnlobstrokkred.png'
    },
    nlpr: {
        icon: 'btnlobstrokkred.png'
    },
    nlps: {
        icon: 'btnlobstrokkred.png'
    },
    nlrv: {
        icon: 'btndeeplordrevenant.png'
    },
    nlsn: {
        icon: 'btnlobstrokkred.png'
    },
    nltc: {
        icon: 'btnlobstrokkgreen.png'
    },
    nltl: {
        icon: 'btnthunderlizard.png'
    },
    nlur: {
        icon: 'btnmonsterlure.png'
    },
    nmam: {
        icon: 'btnmammoth.png'
    },
    nmbg: {
        icon: 'btnmurgulbloodgill.png'
    },
    nmcf: {
        icon: 'btnmurgultidewarrior.png'
    },
    nmdr: {
        icon: 'btnblackmammoth.png'
    },
    nmfs: {
        icon: 'btnmurlocflesheater.png'
    },
    nmgd: {
        icon: 'btnbluemagnataur.png'
    },
    nmgr: {
        icon: 'btnmagnataurbrown.png'
    },
    nmgw: {
        icon: 'btnmagnataur.png'
    },
    nmit: {
        icon: 'btnmammoth.png'
    },
    nmmu: {
        icon: 'btnmurlocmutant.png'
    },
    nmpg: {
        icon: 'btnmurlocmutant.png'
    },
    nmrl: {
        icon: 'btnmurloc.png'
    },
    nmrm: {
        icon: 'btnmurlocnightcrawler.png'
    },
    nmrr: {
        icon: 'btnmurloc.png'
    },
    nmrv: {
        icon: 'btnmurgulbloodgill.png'
    },
    nmsc: {
        icon: 'btnmurgulshadowcaster.png'
    },
    nmsn: {
        icon: 'btnmurgulshadowcaster.png'
    },
    nmtw: {
        icon: 'btnmurgultidewarrior.png'
    },
    nmyr: {
        icon: 'btnnagamyrmidon.png'
    },
    nmys: {
        icon: 'btnnagamyrmidon.png'
    },
    nndk: {
        icon: 'btnnetherdragon.png'
    },
    nndr: {
        icon: 'btnnetherdragon.png'
    },
    nnht: {
        icon: 'btnnetherdragon.png'
    },
    nnmg: {
        icon: 'btnmurgultidewarrior.png'
    },
    nnrg: {
        icon: 'btnnagamyrmidonroyalguard.png'
    },
    nnrs: {
        icon: 'btnnagamyrmidonroyalguard.png'
    },
    nnsu: {
        icon: 'btnnagasummoner.png'
    },
    nnsw: {
        icon: 'btnseawitch.png'
    },
    nnwa: {
        icon: 'btnnerubian.png'
    },
    nnwl: {
        icon: 'btnnerubianqueen.png'
    },
    nnwq: {
        icon: 'btnnerubianqueen.png'
    },
    nnwr: {
        icon: 'btnnerubianspiderlord.png'
    },
    nnws: {
        icon: 'btnnerubianspiderlord.png'
    },
    noga: {
        icon: 'btnarmoredoge.png'
    },
    nogl: {
        icon: 'btnogrelord.png'
    },
    nogm: {
        icon: 'btnogre.png'
    },
    nogn: {
        icon: 'btnoneheadedogre.png'
    },
    nogo: {
        icon: 'btnoneheadedogre.png'
    },
    nogr: {
        icon: 'btnogre.png'
    },
    nomg: {
        icon: 'btnogremagi.png'
    },
    nowb: {
        icon: 'btnowlbear.png'
    },
    nowe: {
        icon: 'btnowlbear.png'
    },
    nowk: {
        icon: 'btnowlbear.png'
    },
    npfl: {
        icon: 'btnpurplefelhound.png'
    },
    npfm: {
        icon: 'btnpurplefelhound.png'
    },
    nplb: {
        icon: 'btnfrostbear.png'
    },
    nplg: {
        icon: 'btnfrostbear.png'
    },
    nqbh: {
        icon: 'btnrazorback.png'
    },
    nrdk: {
        icon: 'btnreddragon.png'
    },
    nrdr: {
        icon: 'btnreddragon.png'
    },
    nrel: {
        icon: 'btnseaelemental.png'
    },
    nrog: {
        icon: 'btnbandit.png'
    },
    nrvd: {
        icon: 'btnrevenant.png'
    },
    nrvf: {
        icon: 'btnrevenant.png'
    },
    nrvi: {
        icon: 'btnrevenant.png'
    },
    nrvl: {
        icon: 'btnrevenant.png'
    },
    nrvs: {
        icon: 'btnrevenant.png'
    },
    nrwm: {
        icon: 'btnreddragon.png'
    },
    nrzb: {
        icon: 'btnrazorback.png'
    },
    nrzg: {
        icon: 'btnrazormanechief.png'
    },
    nrzm: {
        icon: 'btnrazormanechief.png'
    },
    nrzs: {
        icon: 'btnrazorback.png'
    },
    nrzt: {
        icon: 'btnrazorback.png'
    },
    nsat: {
        icon: 'btnsatyrtrickster.png'
    },
    nsbm: {
        icon: 'btnspiderblue.png'
    },
    nsbs: {
        icon: 'btnsnapdragon.png'
    },
    nsc2: {
        icon: 'btnspidercrab.png'
    },
    nsc3: {
        icon: 'btnspidercrab.png'
    },
    nscb: {
        icon: 'btnspidercrab.png'
    },
    nsel: {
        icon: 'btnseaelemental.png'
    },
    nsgb: {
        icon: 'btnseagiant.png'
    },
    nsgg: {
        icon: 'btnarmorgolem.png'
    },
    nsgh: {
        icon: 'btnseagiantgreen.png'
    },
    nsgn: {
        icon: 'btnseagiant.png'
    },
    nsgt: {
        icon: 'btnspider.png'
    },
    nska: {
        icon: 'btnskeletonarcher.png'
    },
    nske: {
        icon: 'btnskeletonwarrior.png'
    },
    nsca: {
        icon: 'btnskeletonarcher.png'
    },
    nsce: {
        icon: 'btnskeletonwarrior.png'
    },
    nskf: {
        icon: 'btnskeletonarcher.png'
    },
    nskg: {
        icon: 'btnskeletonwarrior.png'
    },
    nskm: {
        icon: 'btnskeletonarcher.png'
    },
    nsko: {
        icon: 'btnskeletalorc.png'
    },
    nslf: {
        icon: 'btnsludgecreature.png'
    },
    nslh: {
        icon: 'btnthunderlizardsalamander.png'
    },
    nsll: {
        icon: 'btnthunderlizardsalamander.png'
    },
    nslm: {
        icon: 'btnsludgecreature.png'
    },
    nsln: {
        icon: 'btnsludgecreature.png'
    },
    nslr: {
        icon: 'btnthunderlizardsalamander.png'
    },
    nslv: {
        icon: 'btnthunderlizardvizier.png'
    },
    nsnp: {
        icon: 'btnsnapdragon.png'
    },
    nsns: {
        icon: 'btnmurgulshadowcaster.png'
    },
    nsoc: {
        icon: 'btnskeletalorcchampion.png'
    },
    nsog: {
        icon: 'btnskeletalorcgrunt.png'
    },
    nspb: {
        icon: 'btnspiderblack.png'
    },
    nspd: {
        icon: 'btnnerubian.png'
    },
    nspg: {
        icon: 'btnspidergreen.png'
    },
    nspp: {
        icon: 'btnrazorback.png'
    },
    nspr: {
        icon: 'btnspider.png'
    },
    nsqa: {
        icon: 'btnsasquatch.png'
    },
    nsqe: {
        icon: 'btnsasquatchshaman.png'
    },
    nsqo: {
        icon: 'btnsasquatch.png'
    },
    nsqt: {
        icon: 'btnsasquatch.png'
    },
    nsra: {
        icon: 'btnorcwarlock.png'
    },
    nsrh: {
        icon: 'btnorcwarlock.png'
    },
    nsrn: {
        icon: 'btnorcwarlock.png'
    },
    nsrv: {
        icon: 'btnrevenant.png'
    },
    nsrw: {
        icon: 'btnorcwarlock.png'
    },
    nssp: {
        icon: 'btnspiderblue.png'
    },
    nsth: {
        icon: 'btnsatyr.png'
    },
    nstl: {
        icon: 'btnsatyr.png'
    },
    nsts: {
        icon: 'btnsatyrtrickster.png'
    },
    nstw: {
        icon: 'btnthunderlizard.png'
    },
    nsty: {
        icon: 'btnsatyr.png'
    },
    nthl: {
        icon: 'btnthunderlizard.png'
    },
    ntka: {
        icon: 'btntuskaargold.png'
    },
    ntkc: {
        icon: 'btntuskaarblack.png'
    },
    ntkf: {
        icon: 'btntuskaarbrown.png'
    },
    ntkh: {
        icon: 'btntuskaarbrown.png'
    },
    ntks: {
        icon: 'btntuskaarbrown.png'
    },
    ntkt: {
        icon: 'btntuskaargold.png'
    },
    ntkw: {
        icon: 'btntuskaarbrown.png'
    },
    ntor: {
        icon: 'btntornado.png'
    },
    ntrd: {
        icon: 'btnseaturtlered.png'
    },
    ntrg: {
        icon: 'btnseaturtlegreen.png'
    },
    ntrh: {
        icon: 'btnseaturtlegreen.png'
    },
    ntrs: {
        icon: 'btnseaturtlegreen.png'
    },
    ntrt: {
        icon: 'btnseaturtlegreen.png'
    },
    ntrv: {
        icon: 'btnrevenant.png'
    },
    ntws: {
        icon: 'btnmurgultidewarrior.png'
    },
    nubk: {
        icon: 'btnunbroken.png'
    },
    nubr: {
        icon: 'btnunbroken.png'
    },
    nubw: {
        icon: 'btnunbroken.png'
    },
    nvde: {
        icon: 'btnvoidwalker.png'
    },
    nvdg: {
        icon: 'btnvoidwalker.png'
    },
    nvdl: {
        icon: 'btnvoidwalker.png'
    },
    nvdw: {
        icon: 'btnvoidwalker.png'
    },
    nwen: {
        icon: 'btnwendigo.png'
    },
    nwgs: {
        icon: 'btnwindserpent.png'
    },
    nwiz: {
        icon: 'btnbanditmage.png'
    },
    nwld: {
        icon: 'btndirewolf.png'
    },
    nwlg: {
        icon: 'btntimberwolf.png'
    },
    nwlt: {
        icon: 'btntimberwolf.png'
    },
    nwna: {
        icon: 'btnwendigo.png'
    },
    nwnr: {
        icon: 'btnwendigo.png'
    },
    nwns: {
        icon: 'btnwendigo.png'
    },
    nwrg: {
        icon: 'btnarmorgolem.png'
    },
    nws1: {
        icon: 'btndragonhawk.png'
    },
    nwwd: {
        icon: 'btntimberwolf.png'
    },
    nwwf: {
        icon: 'btntimberwolf.png'
    },
    nwwg: {
        icon: 'btntimberwolf.png'
    },
    nwzd: {
        icon: 'btnbanditmage.png'
    },
    nwzg: {
        icon: 'btnbanditmage.png'
    },
    nwzr: {
        icon: 'btnbanditmage.png'
    },
    nzep: {
        icon: 'btngoblinzeppelin.png'
    },
    nzom: {
        icon: 'btnzombie.png'
    },
    nchp: {
        icon: 'btnbanditmage.png'
    },
    nhym: {
        icon: 'btnbanditmage.png'
    },
    nalb: {
        icon: 'btnalbatross.png'
    },
    ncrb: {
        icon: 'btnspinycrab.png'
    },
    nfro: {
        icon: 'btnhex.png'
    },
    nech: {
        icon: 'btncritterchicken.png'
    },
    nrac: {
        icon: 'btnracoon.png'
    },
    necr: {
        icon: 'btncritterrabbit.png'
    },
    nder: {
        icon: 'btnstag.png'
    },
    ndog: {
        icon: 'btnwolf.png'
    },
    ndwm: {
        icon: 'btnduneworm.png'
    },
    nfbr: {
        icon: 'btnfelboar.png'
    },
    nhmc: {
        icon: 'btnhermitcrab.png'
    },
    now2: {
        icon: 'btnscout.png'
    },
    now3: {
        icon: 'btnscout.png'
    },
    nowl: {
        icon: 'btnscout.png'
    },
    npig: {
        icon: 'btnpig.png'
    },
    npng: {
        icon: 'btnpenguin.png'
    },
    npnw: {
        icon: 'btnpenguin.png'
    },
    nrat: {
        icon: 'btnyoudirtyrat!.png'
    },
    nsea: {
        icon: 'btnseal.png'
    },
    nsha: {
        icon: 'btnsheep.png'
    },
    nshe: {
        icon: 'btnsheep.png'
    },
    nshf: {
        icon: 'btnsheep.png'
    },
    nshw: {
        icon: 'btnsheep.png'
    },
    nskk: {
        icon: 'btnskink.png'
    },
    nsno: {
        icon: 'btnsnowowl.png'
    },
    nvil: {
        icon: 'btnvillagerman.png'
    },
    nvk2: {
        icon: 'btnvillagerkid2.png'
    },
    nvl2: {
        icon: 'btnvillagerman1.png'
    },
    nvlk: {
        icon: 'btnvillagerkid.png'
    },
    nvlw: {
        icon: 'btnvillagerwoman.png'
    },
    nvul: {
        icon: 'btnvulture.png'
    },
    ncb0: {
        icon: 'btngenerichumanbuilding.png'
    },
    ncb1: {
        icon: 'btngenerichumanbuilding.png'
    },
    ncb2: {
        icon: 'btngenerichumanbuilding.png'
    },
    ncb3: {
        icon: 'btngenerichumanbuilding.png'
    },
    ncb4: {
        icon: 'btngenerichumanbuilding.png'
    },
    ncb5: {
        icon: 'btngenerichumanbuilding.png'
    },
    ncb6: {
        icon: 'btngenerichumanbuilding.png'
    },
    ncb7: {
        icon: 'btngenerichumanbuilding.png'
    },
    ncb8: {
        icon: 'btngenerichumanbuilding.png'
    },
    ncb9: {
        icon: 'btngenerichumanbuilding.png'
    },
    ncba: {
        icon: 'btngenerichumanbuilding.png'
    },
    ncbb: {
        icon: 'btngenerichumanbuilding.png'
    },
    ncbc: {
        icon: 'btngenerichumanbuilding.png'
    },
    ncbd: {
        icon: 'btngenerichumanbuilding.png'
    },
    ncbe: {
        icon: 'btngenerichumanbuilding.png'
    },
    ncbf: {
        icon: 'btngenerichumanbuilding.png'
    },
    ncnt: {
        icon: 'btngenericcreepbuilding.png'
    },
    ncop: {
        icon: 'btncop.png'
    },
    ncp2: {
        icon: 'btncop.png'
    },
    ncp3: {
        icon: 'btncop.png'
    },
    nct1: {
        icon: 'btngenericcreepbuilding.png'
    },
    nct2: {
        icon: 'btngenericcreepbuilding.png'
    },
    ndch: {
        icon: 'btndranaichiefhut.png'
    },
    ndh0: {
        icon: 'btndranaihut.png'
    },
    ndh1: {
        icon: 'btndranaihut.png'
    },
    ndh2: {
        icon: 'btndranaichiefhut.png'
    },
    ndh3: {
        icon: 'btndranaihut.png'
    },
    ndh4: {
        icon: 'btndranaihut.png'
    },
    ndrg: {
        icon: 'btngreendragonroost.png'
    },
    ndrk: {
        icon: 'btnblackdragonroost.png'
    },
    ndro: {
        icon: 'btnblackdragonroost.png'
    },
    ndrr: {
        icon: 'btndragonroost.png'
    },
    ndru: {
        icon: 'btnbluedragonroost.png'
    },
    ndrz: {
        icon: 'btndragonroost.png'
    },
    nfh0: {
        icon: 'btngenericcreepbuilding.png'
    },
    nfh1: {
        icon: 'btngenericcreepbuilding.png'
    },
    nfoh: {
        icon: 'btnfountainoflife.png'
    },
    nfr1: {
        icon: 'btngenericcreepbuilding.png'
    },
    nfr2: {
        icon: 'btngenericcreepbuilding.png'
    },
    ngad: {
        icon: 'btnammodump.png'
    },
    ngme: {
        icon: 'btnmerchant.png'
    },
    ngnh: {
        icon: 'btngenericcreepbuilding.png'
    },
    ngni: {
        icon: 'btngenerichumanbuilding.png'
    },
    ngol: {
        icon: 'btngoldmine.png'
    },
    ngt2: {
        icon: 'btngenericcreepbuilding.png'
    },
    ngwr: {
        icon: 'btngenerichumanbuilding.png'
    },
    nhns: {
        icon: 'btngenericcreepbuilding.png'
    },
    nmer: {
        icon: 'btnmercenarycamp.png'
    },
    nmg0: {
        icon: 'btngenericcreepbuilding.png'
    },
    nmg1: {
        icon: 'btngenericcreepbuilding.png'
    },
    nmh0: {
        icon: 'btngenericcreepbuilding.png'
    },
    nmh1: {
        icon: 'btngenericcreepbuilding.png'
    },
    nmoo: {
        icon: 'btnfountainoflife.png'
    },
    nmr0: {
        icon: 'btnmercenarycamp.png'
    },
    nmr2: {
        icon: 'btnmercenarycamp.png'
    },
    nmr3: {
        icon: 'btnmercenarycamp.png'
    },
    nmr4: {
        icon: 'btnmercenarycamp.png'
    },
    nmr5: {
        icon: 'btnmercenarycamp.png'
    },
    nmr6: {
        icon: 'btnmercenarycamp.png'
    },
    nmr7: {
        icon: 'btnmercenarycamp.png'
    },
    nmr8: {
        icon: 'btnmercenarycamp.png'
    },
    nmr9: {
        icon: 'btnmercenarycamp.png'
    },
    nmra: {
        icon: 'btnmercenarycamp.png'
    },
    nmrb: {
        icon: 'btnmercenarycamp.png'
    },
    nmrc: {
        icon: 'btnmercenarycamp.png'
    },
    nmrd: {
        icon: 'btnmercenarycamp.png'
    },
    nmre: {
        icon: 'btnmercenarycamp.png'
    },
    nmrf: {
        icon: 'btnmercenarycamp.png'
    },
    nmrk: {
        icon: 'btnblackmarket.png'
    },
    nnzg: {
        icon: 'btnnerubianziggurat.png'
    },
    nshp: {
        icon: 'btngoblinshipyard.png'
    },
    ntav: {
        icon: 'btntavern.png'
    },
    nten: {
        icon: 'btngenericcreepbuilding.png'
    },
    nth0: {
        icon: 'btngenericcreepbuilding.png'
    },
    nth1: {
        icon: 'btngenericcreepbuilding.png'
    },
    ntn2: {
        icon: 'btngenericcreepbuilding.png'
    },
    ntnt: {
        icon: 'btntaurenhut.png'
    },
    ntt2: {
        icon: 'btntaurenhut.png'
    },
    nwgt: {
        icon: 'btnwaygate.png'
    },
    Ecen: {
        icon: 'btnkeeperofthegrove.png'
    },
    Eevi: {
        icon: 'btnevilillidan.png'
    },
    Eevm: {
        icon: 'btnmetamorphosis.png'
    },
    Efur: {
        icon: 'btnfurion.png'
    },
    Eidm: {
        icon: 'btnherodemonhunter.png'
    },
    Eill: {
        icon: 'btnherodemonhunter.png'
    },
    Eilm: {
        icon: 'btnherodemonhunter.png'
    },
    Ekgg: {
        icon: 'btnkeeperghostblue.png'
    },
    Emfr: {
        icon: 'btnfurion.png'
    },
    Emns: {
        icon: 'btnfurion.png'
    },
    Etyr: {
        icon: 'btnpriestessofthemoon.png'
    },
    Ewrd: {
        icon: 'btnwarden2.png'
    },
    Hant: {
        icon: 'btnheroarchmage.png'
    },
    Hapm: {
        icon: 'btnheropaladin.png'
    },
    Harf: {
        icon: 'btnarthas.png'
    },
    Hart: {
        icon: 'btnarthas.png'
    },
    Hdgo: {
        icon: 'btnheropaladin.png'
    },
    Hhkl: {
        icon: 'btnheropaladin.png'
    },
    Hjai: {
        icon: 'btnjaina.png'
    },
    Hkal: {
        icon: 'btnbloodmage2.png'
    },
    Hlgr: {
        icon: 'btngarithos.png'
    },
    Hmbr: {
        icon: 'btnheromountainking.png'
    },
    Hmgd: {
        icon: 'btnheropaladin.png'
    },
    Hpb1: {
        icon: 'btnheropaladin.png'
    },
    Hpb2: {
        icon: 'btnheropaladin.png'
    },
    Huth: {
        icon: 'btnheropaladin.png'
    },
    Hvsh: {
        icon: 'btnnagaseawitch.png'
    },
    Hvwd: {
        icon: 'btnsylvanuswindrunner.png'
    },
    Nbbc: {
        icon: 'btnchaosblademaster.png'
    },
    Nklj: {
        icon: 'btnkiljaedin.png'
    },
    Nkjx: {
        icon: 'btnkiljaedin.png'
    },
    Nmag: {
        icon: 'btnpitlord.png'
    },
    Nman: {
        icon: 'btnmannoroth.png'
    },
    Npld: {
        icon: 'btnpitlord.png'
    },
    Nsjs: {
        icon: 'btnpandarenbrewmaster.png'
    },
    Ocbh: {
        icon: 'btnherotaurenchieftain.png'
    },
    Ocb2: {
        icon: 'btnherotaurenchieftain.png'
    },
    Odrt: {
        icon: 'btnherofarseer.png'
    },
    Ogld: {
        icon: 'btnguldan.png'
    },
    Ogrh: {
        icon: 'btnhellscream.png'
    },
    Opgh: {
        icon: 'btnchaosgrom.png'
    },
    Orex: {
        icon: 'btnbeastmaster.png'
    },
    Orkn: {
        icon: 'btnshadowhunter.png'
    },
    Osam: {
        icon: 'btnheroblademaster.png'
    },
    Otcc: {
        icon: 'btnherotaurenchieftain.png'
    },
    Othr: {
        icon: 'btnthrall.png'
    },
    Uanb: {
        icon: 'btnherocryptlord.png'
    },
    Ubal: {
        icon: 'btntichondrius.png'
    },
    Uclc: {
        icon: 'btnlichversion2.png'
    },
    Udth: {
        icon: 'btnherodreadlord.png'
    },
    Uear: {
        icon: 'btnherodeathknight.png'
    },
    Uktl: {
        icon: 'btnlichversion2.png'
    },
    Umal: {
        icon: 'btnherodreadlord.png'
    },
    Usyl: {
        icon: 'btnbansheeranger.png'
    },
    Utic: {
        icon: 'btntichondrius.png'
    },
    Uvar: {
        icon: 'btnherodreadlord.png'
    },
    Uvng: {
        icon: 'btnherodreadlord.png'
    },
    Uwar: {
        icon: 'btnarchimonde.png'
    },
    Hgam: {
        icon: 'btnghostmage.png'
    },
    eilw: {
        icon: 'btncagedillidan.png'
    },
    enec: {
        icon: 'btnnightelfrunner.png'
    },
    ensh: {
        icon: 'btnhuntress.png'
    },
    eshd: {
        icon: 'btnshandris.png'
    },
    etrs: {
        icon: 'btnnightelftransport.png'
    },
    hbew: {
        icon: 'btnbloodelfsupplywagon.png'
    },
    hcth: {
        icon: 'btnthecaptain.png'
    },
    hhdl: {
        icon: 'btnriderlesshorse.png'
    },
    hhes: {
        icon: 'btnthecaptain.png'
    },
    hrdh: {
        icon: 'btnriderlesshorse.png'
    },
    Naka: {
        icon: 'btndranaiakama.png'
    },
    nsw1: {
        icon: 'btnfelhound.png'
    },
    nsw2: {
        icon: 'btnfelhound.png'
    },
    nsw3: {
        icon: 'btnfelhound.png'
    },
    ncat: {
        icon: 'btncatapult.png'
    },
    nbee: {
        icon: 'btnbloodelfpeasant.png'
    },
    nbel: {
        icon: 'btnspellbreaker.png'
    },
    nbsp: {
        icon: 'btnship.png'
    },
    nchg: {
        icon: 'btnchaosgrunt.png'
    },
    nchr: {
        icon: 'btnchaoswolfrider.png'
    },
    nchw: {
        icon: 'btnchaoswarlock.png'
    },
    nckb: {
        icon: 'btnchaoskotobeast.png'
    },
    ncpn: {
        icon: 'btnchaospeon.png'
    },
    ndmu: {
        icon: 'btndalaranmutant.png'
    },
    ndrd: {
        icon: 'btndranai.png'
    },
    ndrf: {
        icon: 'btndranai.png'
    },
    ndrn: {
        icon: 'btndranai.png'
    },
    ndrt: {
        icon: 'btndranai.png'
    },
    ndrh: {
        icon: 'btndranaimage.png'
    },
    ndrl: {
        icon: 'btndranai.png'
    },
    ndsa: {
        icon: 'btnthunderlizardvizier.png'
    },
    ndrj: {
        icon: 'btndalaranreject.png'
    },
    ndrm: {
        icon: 'btndranaimage.png'
    },
    ndrp: {
        icon: 'btndranai.png'
    },
    ndrs: {
        icon: 'btndranaimage.png'
    },
    ndrw: {
        icon: 'btndranai.png'
    },
    negz: {
        icon: 'btngoblinsapper.png'
    },
    nemi: {
        icon: 'btnbanditmage.png'
    },
    nfgl: {
        icon: 'btnfleshgolem.png'
    },
    ngbl: {
        icon: 'btnjunkgolem.png'
    },
    nhea: {
        icon: 'btnhighelvenarcher.png'
    },
    nhef: {
        icon: 'btnfemaleelfvillager.png'
    },
    nhem: {
        icon: 'btnelfvillager.png'
    },
    nhew: {
        icon: 'btnelfvillager.png'
    },
    njks: {
        icon: 'btnbandit.png'
    },
    nmdm: {
        icon: 'btnravenform.png'
    },
    nmed: {
        icon: 'btnmedivh.png'
    },
    nmpe: {
        icon: 'btnmurgalslave.png'
    },
    nmsh: {
        icon: 'btngrizzlybear.png'
    },
    nser: {
        icon: 'btnblackdragon.png'
    },
    nspc: {
        icon: 'btnsupportbeam.png'
    },
    nssn: {
        icon: 'btnassassin.png'
    },
    nthr: {
        icon: 'btngreendragon.png'
    },
    nw2w: {
        icon: 'btnchaoswarlockgreen.png'
    },
    nwat: {
        icon: 'btnhuntress.png'
    },
    odkt: {
        icon: 'btnorcwarlockred.png'
    },
    ogrk: {
        icon: 'btngrunt.png'
    },
    ojgn: {
        icon: 'btnjuggernaut.png'
    },
    omtg: {
        icon: 'btnchaoswarlord.png'
    },
    onzg: {
        icon: 'btnraider.png'
    },
    oosc: {
        icon: 'btnriderlesskodo.png'
    },
    oswy: {
        icon: 'btnspiritwyvern.png'
    },
    ovlj: {
        icon: 'btnwitchdoctor.png'
    },
    owar: {
        icon: 'btnchaoswarlord.png'
    },
    ownr: {
        icon: 'btnwyvern.png'
    },
    uabc: {
        icon: 'btnabomination.png'
    },
    uarb: {
        icon: 'btnundeadairbarge.png'
    },
    ubdd: {
        icon: 'btnfrostwyrm.png'
    },
    ubdr: {
        icon: 'btnazuredragon.png'
    },
    ubot: {
        icon: 'btnundeadtransport.png'
    },
    udes: {
        icon: 'btnundeaddestroyer.png'
    },
    uktg: {
        icon: 'btnghostofkelthuzad.png'
    },
    uktn: {
        icon: 'btnkelthuzad.png'
    },
    uswb: {
        icon: 'btnghost.png'
    },
    hprt: {
        icon: 'btnportal.png'
    },
    haro: {
        icon: 'btnarcaneobservatory.png'
    },
    nbfl: {
        icon: 'btnfountainoflifeblood.png'
    },
    nbsm: {
        icon: 'btnbookofsummoning.png'
    },
    nbt1: {
        icon: 'btnrocktower.png'
    },
    nbt2: {
        icon: 'btnadvancedrocktower.png'
    },
    nbwd: {
        icon: 'btnbearden.png'
    },
    ncap: {
        icon: 'btncorruptedancientprotector.png'
    },
    ncaw: {
        icon: 'btncorruptedancientofwar.png'
    },
    ncmw: {
        icon: 'btncorruptedmoonwell.png'
    },
    ncta: {
        icon: 'btntreeofages.png'
    },
    ncte: {
        icon: 'btntreeofeternity.png'
    },
    nctl: {
        icon: 'btncorruptedtreeoflife.png'
    },
    ndfl: {
        icon: 'btnfountainoflifedefiled.png'
    },
    ndgt: {
        icon: 'btndalaranguardtower.png'
    },
    ndke: {
        icon: 'btndarkportal.png'
    },
    ndkw: {
        icon: 'btndarkportal.png'
    },
    ndmg: {
        icon: 'btndemongate.png'
    },
    ndrb: {
        icon: 'btndragonroost.png'
    },
    ndt1: {
        icon: 'btncoldtower.png'
    },
    ndt2: {
        icon: 'btnadvancedfrosttower.png'
    },
    nef0: {
        icon: 'btnelvenfarm.png'
    },
    nef1: {
        icon: 'btnelvenfarm.png'
    },
    nef2: {
        icon: 'btnelvenfarm.png'
    },
    nef3: {
        icon: 'btnelvenfarm.png'
    },
    nef4: {
        icon: 'btnelvenfarm.png'
    },
    nef5: {
        icon: 'btnelvenfarm.png'
    },
    nef6: {
        icon: 'btnelvenfarm.png'
    },
    nef7: {
        icon: 'btnelvenfarm.png'
    },
    nefm: {
        icon: 'btnelvenfarm.png'
    },
    negf: {
        icon: 'btnelvenguardtower.png'
    },
    negm: {
        icon: 'btnelvenguardtower.png'
    },
    negt: {
        icon: 'btnelvenguardtower.png'
    },
    net1: {
        icon: 'btnenergytower.png'
    },
    net2: {
        icon: 'btnadvancedenergytower.png'
    },
    nfnp: {
        icon: 'btnfountainoflife.png'
    },
    nfrm: {
        icon: 'btnfrostmourne.png'
    },
    nfrt: {
        icon: 'btnmarketplace.png'
    },
    nft1: {
        icon: 'btnflametower.png'
    },
    nft2: {
        icon: 'btnadvancedflametower.png'
    },
    nfv0: {
        icon: 'btnnightelffishingvillage.png'
    },
    nfv1: {
        icon: 'btnnightelffishingvillage.png'
    },
    nfv2: {
        icon: 'btnnightelffishingvillage.png'
    },
    nfv3: {
        icon: 'btnnightelffishingvillage.png'
    },
    nfv4: {
        icon: 'btnnightelffishingvillage.png'
    },
    ngob: {
        icon: 'btnenchantedgemstone.png'
    },
    nhcn: {
        icon: 'btnhornofcenarius.png'
    },
    nheb: {
        icon: 'btnmagetower.png'
    },
    nico: {
        icon: 'btnicecrownobelisk.png'
    },
    nitb: {
        icon: 'btnicytreasurebox.png'
    },
    nmgv: {
        icon: 'btnmagicvault.png'
    },
    nnad: {
        icon: 'btnaltarofdepths.png'
    },
    nnfm: {
        icon: 'btncoralbed.png'
    },
    nnsa: {
        icon: 'btnshrineofaszhara.png'
    },
    nnsg: {
        icon: 'btnspawninggrounds.png'
    },
    nntg: {
        icon: 'btntidalguardian.png'
    },
    nntt: {
        icon: 'btntempleoftides.png'
    },
    npgf: {
        icon: 'btnpigfarm.png'
    },
    npgr: {
        icon: 'btnpowergenerator.png'
    },
    nshr: {
        icon: 'btnundeadshrine.png'
    },
    ntt1: {
        icon: 'btndeathtower.png'
    },
    ntx2: {
        icon: 'btnadvanceddeathtower.png'
    },
    nvr0: {
        icon: 'btnnightelffishingvillage.png'
    },
    nvr1: {
        icon: 'btnnightelffishingvillage.png'
    },
    nvr2: {
        icon: 'btnnightelffishingvillage.png'
    },
    nwc1: {
        icon: 'btncage.png'
    },
    nwc2: {
        icon: 'btncage.png'
    },
    ocbw: {
        icon: 'btntrollburrow.png'
    },
    nzin: {
        icon: 'btnportal.png'
    },
    nbse: {
        icon: 'btnresstone.png'
    },
    nbsw: {
        icon: 'btnresstone.png'
    },
    zcso: {
        icon: 'btnchaosspaceorc.png'
    },
    zhyd: {
        icon: 'btnhydralisk.png'
    },
    zjug: {
        icon: 'btnjuggernaut.png'
    },
    zmar: {
        icon: 'btnmarine.png'
    },
    zshv: {
        icon: 'btntemp.png'
    },
    zsmc: {
        icon: 'btntemp.png'
    },
    zzrg: {
        icon: 'btnzergling.png'
    },
    nzlc: {
        icon: 'btnrevenant.png'
    },
    ckng: {
        icon: 'btnhelmutpurple.png'
    },
    modt: {
        icon: 'btnmaskofdeath.png'
    },
    tkno: {
        icon: 'btntomered.png'
    },
    ratf: {
        icon: 'btnclawsofattack.png'
    },
    ofro: {
        icon: 'btnorboffrost.png'
    },
    desc: {
        icon: 'btndaggerofescape.png'
    },
    fgdg: {
        icon: 'btndoomguard.png'
    },
    infs: {
        icon: 'btninfernalstone.png'
    },
    shar: {
        icon: 'btniceshard.png'
    },
    sand: {
        icon: 'btnsnazzyscrollpurple.png'
    },
    wild: {
        icon: 'btnamuletofthewild.png'
    },
    srrc: {
        icon: 'btnsnazzyscroll.png'
    },
    odef: {
        icon: 'btnorbofdarkness.png'
    },
    rde4: {
        icon: 'btnringgreen.png'
    },
    pmna: {
        icon: 'btnpendantofmana.png'
    },
    rhth: {
        icon: 'btnperiapt1.png'
    },
    ssil: {
        icon: 'btnstaffofsilence.png'
    },
    spsh: {
        icon: 'btnspellshieldamulet.png'
    },
    sres: {
        icon: 'btnscrollofhealing.png'
    },
    pdiv: {
        icon: 'btnpotionofdivinity.png'
    },
    pres: {
        icon: 'btnpotionofrestoration.png'
    },
    totw: {
        icon: 'btnstone.png'
    },
    fgfh: {
        icon: 'btnfelhound.png'
    },
    fgrd: {
        icon: 'btnreddragon.png'
    },
    fgrg: {
        icon: 'btnrockgolem.png'
    },
    hcun: {
        icon: 'btnhoodofcunning.png'
    },
    hval: {
        icon: 'btnhelmofvalor.png'
    },
    mcou: {
        icon: 'btnmedalionofcourage.png'
    },
    ajen: {
        icon: 'btnjanggo.png'
    },
    clfm: {
        icon: 'btncloakofflames.png'
    },
    ratc: {
        icon: 'btnclawsofattack.png'
    },
    war2: {
        icon: 'btndrum.png'
    },
    kpin: {
        icon: 'btnpipeofinsight.png'
    },
    lgdh: {
        icon: 'btnhornofdoom.png'
    },
    ankh: {
        icon: 'btnankh.png'
    },
    whwd: {
        icon: 'btnhealingward.png'
    },
    fgsk: {
        icon: 'btnbookofthedead.png'
    },
    wcyc: {
        icon: 'btnwandofcyclone.png'
    },
    hlst: {
        icon: 'btnhealthstone.png'
    },
    mnst: {
        icon: 'btnmanastone.png'
    },
    belv: {
        icon: 'btnboots.png'
    },
    bgst: {
        icon: 'btnbelt.png'
    },
    ciri: {
        icon: 'btnrobeofthemagi.png'
    },
    lhst: {
        icon: 'btnlionhorn.png'
    },
    afac: {
        icon: 'btnalleriaflute.png'
    },
    sbch: {
        icon: 'btnbonechimes.png'
    },
    brac: {
        icon: 'btnrunedbracers.png'
    },
    rwiz: {
        icon: 'btnsobimask.png'
    },
    pghe: {
        icon: 'btnpotiongreen.png'
    },
    pgma: {
        icon: 'btnpotionbluebig.png'
    },
    pnvu: {
        icon: 'btngreaterinvulneralbility.png'
    },
    sror: {
        icon: 'btnsnazzyscrollgreen.png'
    },
    woms: {
        icon: 'btnwandofmanasteal.png'
    },
    crys: {
        icon: 'btncrystalball.png'
    },
    evtl: {
        icon: 'btntalisman.png'
    },
    penr: {
        icon: 'btnpendantofenergy.png'
    },
    prvt: {
        icon: 'btnperiapt.png'
    },
    rat9: {
        icon: 'btnclawsofattack.png'
    },
    rde3: {
        icon: 'btnringgreen.png'
    },
    rlif: {
        icon: 'btnringskull.png'
    },
    bspd: {
        icon: 'btnbootsofspeed.png'
    },
    rej3: {
        icon: 'btnrejuvpotion.png'
    },
    will: {
        icon: 'btnwand.png'
    },
    wlsd: {
        icon: 'btnstarwand.png'
    },
    wswd: {
        icon: 'btnsentryward.png'
    },
    cnob: {
        icon: 'btncirclet.png'
    },
    gcel: {
        icon: 'btnglove.png'
    },
    rat6: {
        icon: 'btnclawsofattack.png'
    },
    rde2: {
        icon: 'btnringgreen.png'
    },
    tdx2: {
        icon: 'btntome.png'
    },
    tin2: {
        icon: 'btntome.png'
    },
    tpow: {
        icon: 'btntomered.png'
    },
    tst2: {
        icon: 'btntome.png'
    },
    pnvl: {
        icon: 'btnlesserinvulneralbility.png'
    },
    clsd: {
        icon: 'btncloak.png'
    },
    rag1: {
        icon: 'btnslippersofagility.png'
    },
    rin1: {
        icon: 'btnmantleofintelligence.png'
    },
    rst1: {
        icon: 'btngauntletsofogrepower.png'
    },
    manh: {
        icon: 'btnmanual.png'
    },
    tdex: {
        icon: 'btntome.png'
    },
    tint: {
        icon: 'btntome.png'
    },
    tstr: {
        icon: 'btntome.png'
    },
    pomn: {
        icon: 'btnpotionofomniscience.png'
    },
    wshs: {
        icon: 'btnwandofshadowsight.png'
    },
    rej6: {
        icon: 'btngreaterrejuvscroll.png'
    },
    rej5: {
        icon: 'btnlesserrejuvscroll.png'
    },
    rej4: {
        icon: 'btngreaterrejuvpotion.png'
    },
    ram4: {
        icon: 'btnringjadefalcon.png'
    },
    dsum: {
        icon: 'btndarksummoning.png'
    },
    ofir: {
        icon: 'btnorboffire.png'
    },
    ocor: {
        icon: 'btnorbofcorruption.png'
    },
    oli2: {
        icon: 'btnorboflightning.png'
    },
    oven: {
        icon: 'btnorbofvenom.png'
    },
    ram3: {
        icon: 'btnringjadefalcon.png'
    },
    tret: {
        icon: 'btntomeofretraining.png'
    },
    tgrh: {
        icon: 'btngreathall.png'
    },
    rej2: {
        icon: 'btnlesserrejuvpotion.png'
    },
    gemt: {
        icon: 'btngem.png'
    },
    ram2: {
        icon: 'btnringjadefalcon.png'
    },
    stel: {
        icon: 'btnstaffofteleportation.png'
    },
    stwp: {
        icon: 'btnscrolluber.png'
    },
    wneg: {
        icon: 'btnwandskull.png'
    },
    sneg: {
        icon: 'btnstaffofnegation.png'
    },
    wneu: {
        icon: 'btnwandofneutralization.png'
    },
    shea: {
        icon: 'btnscrolloftownportal.png'
    },
    sman: {
        icon: 'btnscrollofprotection.png'
    },
    rej1: {
        icon: 'btnminorrejuvpotion.png'
    },
    pspd: {
        icon: 'btnpotionred.png'
    },
    dust: {
        icon: 'btndustofappearance.png'
    },
    ram1: {
        icon: 'btnringjadefalcon.png'
    },
    pinv: {
        icon: 'btnlesserinvisibility.png'
    },
    phea: {
        icon: 'btnpotiongreensmall.png'
    },
    pman: {
        icon: 'btnpotionbluesmall.png'
    },
    spro: {
        icon: 'btnscroll.png'
    },
    hslv: {
        icon: 'btnhealingsalve.png'
    },
    moon: {
        icon: 'btnmoonstone.png'
    },
    shas: {
        icon: 'btnscrollofhaste.png'
    },
    skul: {
        icon: 'btnsacrificialskull.png'
    },
    mcri: {
        icon: 'btnmechanicalcritter.png'
    },
    rnec: {
        icon: 'btnrodofnecromancy.png'
    },
    ritd: {
        icon: 'btnsacrificialdagger.png'
    },
    tsct: {
        icon: 'btnhumanwatchtower.png'
    },
    azhr: {
        icon: 'btnheartofaszune.png'
    },
    bzbe: {
        icon: 'btnvialempty.png'
    },
    bzbf: {
        icon: 'btnvialfull.png'
    },
    ches: {
        icon: 'btncheese.png'
    },
    cnhn: {
        icon: 'btnhornofcenarius.png'
    },
    glsk: {
        icon: 'btnguldanskull.png'
    },
    gopr: {
        icon: 'btnglyph.png'
    },
    k3m1: {
        icon: 'btn3m1.png'
    },
    k3m2: {
        icon: 'btn3m2.png'
    },
    k3m3: {
        icon: 'btn3m3.png'
    },
    ktrm: {
        icon: 'btnurnofkelthuzad.png'
    },
    kybl: {
        icon: 'btnbloodkey.png'
    },
    kygh: {
        icon: 'btnghostkey.png'
    },
    kymn: {
        icon: 'btnmoonkey.png'
    },
    kysn: {
        icon: 'btnsunkey.png'
    },
    ledg: {
        icon: 'btnsorceressmaster.png'
    },
    phlt: {
        icon: 'btnundeadshrine.png'
    },
    sehr: {
        icon: 'btnheartofsearinox.png'
    },
    engs: {
        icon: 'btnenchantedgemstone.png'
    },
    sorf: {
        icon: 'btnorbofdarkness.png'
    },
    gmfr: {
        icon: 'btngem.png'
    },
    jpnt: {
        icon: 'btnscrollofprotection.png'
    },
    shwd: {
        icon: 'btnshimmerweed.png'
    },
    skrt: {
        icon: 'btnorbofcorruption.png'
    },
    thle: {
        icon: 'btnthunderlizardegg.png'
    },
    sclp: {
        icon: 'btnselecthero.png'
    },
    wtlg: {
        icon: 'btnwirtsleg.png'
    },
    wolg: {
        icon: 'btnwirtsotherleg.png'
    },
    mgtk: {
        icon: 'btnblood&ghostkey.png'
    },
    mort: {
        icon: 'btnspy.png'
    },
    dphe: {
        icon: 'btnthunderlizardegg.png'
    },
    dkfw: {
        icon: 'btnbarrel.png'
    },
    dthb: {
        icon: 'btnmanaflareoff.png'
    },
    fgun: {
        icon: 'btnflare.png'
    },
    lure: {
        icon: 'btnmonsterlure.png'
    },
    olig: {
        icon: 'btnorboflightning.png'
    },
    amrc: {
        icon: 'btnamulet.png'
    },
    ccmd: {
        icon: 'btnscepterofmastery.png'
    },
    flag: {
        icon: 'btnhumancaptureflag.png'
    },
    gobm: {
        icon: 'btngoblinlandmine.png'
    },
    gsou: {
        icon: 'btnsoulgem.png'
    },
    nflg: {
        icon: 'btnnightelfcaptureflag.png'
    },
    nspi: {
        icon: 'btnnecklace.png'
    },
    oflg: {
        icon: 'btnorccaptureflag.png'
    },
    pams: {
        icon: 'btnsnazzypotion.png'
    },
    pgin: {
        icon: 'btngreaterinvisibility.png'
    },
    rat3: {
        icon: 'btnclawsofattack.png'
    },
    rde0: {
        icon: 'btnringgreen.png'
    },
    rde1: {
        icon: 'btnringgreen.png'
    },
    rnsp: {
        icon: 'btngoldring.png'
    },
    soul: {
        icon: 'btnusedsoulgem.png'
    },
    tels: {
        icon: 'btntelescope.png'
    },
    tgxp: {
        icon: 'btnmanual3.png'
    },
    uflg: {
        icon: 'btnundeadcaptureflag.png'
    },
    anfg: {
        icon: 'btnclayfigurine.png'
    },
    brag: {
        icon: 'btnringpurple.png'
    },
    drph: {
        icon: 'btndust.png'
    },
    iwbr: {
        icon: 'btnnaturetouchgrow.png'
    },
    jdrn: {
        icon: 'btnringjadefalcon.png'
    },
    lnrn: {
        icon: 'btnringlionhead.png'
    },
    mlst: {
        icon: 'btnhammer.png'
    },
    oslo: {
        icon: 'btnorbofslowness.png'
    },
    sbok: {
        icon: 'btnspellbookbls.png'
    },
    sksh: {
        icon: 'btngrimward.png'
    },
    sprn: {
        icon: 'btnringvioletspider.png'
    },
    tmmt: {
        icon: 'btnentrapmentward.png'
    },
    vddl: {
        icon: 'btnshadowpact.png'
    },
    spre: {
        icon: 'btnstaffofpreservation.png'
    },
    sfog: {
        icon: 'btnhornoffog.png'
    },
    sor1: {
        icon: 'btnorbofdarkness.png'
    },
    sor2: {
        icon: 'btnorbofdarkness.png'
    },
    sor3: {
        icon: 'btnorbofdarkness.png'
    },
    sor4: {
        icon: 'btnorbofdarkness.png'
    },
    sor5: {
        icon: 'btnorbofdarkness.png'
    },
    sor6: {
        icon: 'btnorbofdarkness.png'
    },
    sor7: {
        icon: 'btnorbofdarkness.png'
    },
    sor8: {
        icon: 'btnorbofdarkness.png'
    },
    sor9: {
        icon: 'btnorbofdarkness.png'
    },
    sora: {
        icon: 'btnorbofdarkness.png'
    },
    fwss: {
        icon: 'btngrimward.png'
    },
    shtm: {
        icon: 'btnentrapmentward.png'
    },
    esaz: {
        icon: 'btnheartofaszune.png'
    },
    btst: {
        icon: 'btnorcbattlestandard.png'
    },
    tbsm: {
        icon: 'btnblacksmith.png'
    },
    tfar: {
        icon: 'btnfarm.png'
    },
    tlum: {
        icon: 'btnhumanlumbermill.png'
    },
    tbar: {
        icon: 'btnhumanbarracks.png'
    },
    tbak: {
        icon: 'btnaltarofkings.png'
    },
    gldo: {
        icon: 'btnusedsoulgem.png'
    },
    stre: {
        icon: 'btnwandskull.png'
    },
    horl: {
        icon: 'btnglyph.png'
    },
    hbth: {
        icon: 'btnunholyaura.png'
    },
    blba: {
        icon: 'btnarmorgolem.png'
    },
    rugt: {
        icon: 'btnimprovedunholystrength.png'
    },
    frhg: {
        icon: 'btnadvancedunholystrength.png'
    },
    gvsm: {
        icon: 'btnspellsteal.png'
    },
    crdt: {
        icon: 'btnrevenant.png'
    },
    arsc: {
        icon: 'btnbansheeadept.png'
    },
    scul: {
        icon: 'btnbansheemaster.png'
    },
    tmsc: {
        icon: 'btnnecromanceradept.png'
    },
    dtsb: {
        icon: 'btnsorceressmaster.png'
    },
    grsl: {
        icon: 'btnnecromancermaster.png'
    },
    arsh: {
        icon: 'btnarcanitearmor.png'
    },
    shdt: {
        icon: 'btnlightningshield.png'
    },
    shhn: {
        icon: 'btnhumanarmorupthree.png'
    },
    shen: {
        icon: 'btnthoriumarmor.png'
    },
    thdm: {
        icon: 'btnenchantedgemstone.png'
    },
    stpg: {
        icon: 'btnpenguin.png'
    },
    shrs: {
        icon: 'btnmonsterlure.png'
    },
    bfhr: {
        icon: 'btnphilosophersstone.png'
    },
    cosl: {
        icon: 'btnusedsoulgem.png'
    },
    shcw: {
        icon: 'btnshamanmaster.png'
    },
    srbd: {
        icon: 'btnarcanitemelee.png'
    },
    frgd: {
        icon: 'btnthoriummelee.png'
    },
    envl: {
        icon: 'btnvialfull.png'
    },
    rump: {
        icon: 'btngathergold.png'
    },
    srtl: {
        icon: 'btnorcmeleeupthree.png'
    },
    stwa: {
        icon: 'btnorcmeleeupone.png'
    },
    klmm: {
        icon: 'btnspiritwalkeradepttraining.png'
    },
    rots: {
        icon: 'btnwitchdoctoradept.png'
    },
    axas: {
        icon: 'btnwitchdoctormaster.png'
    },
    mnsf: {
        icon: 'btnbrilliance.png'
    },
    schl: {
        icon: 'btnpriestadept.png'
    },
    asbl: {
        icon: 'btndaggerofescape.png'
    },
    kgal: {
        icon: 'btnbarrel.png'
    },
    ward: {
        icon: 'btndrum.png'
    },
    gold: {
        icon: 'btnchestofgold.png'
    },
    lmbr: {
        icon: 'btnbundleoflumber.png'
    },
    gfor: {
        icon: 'btnglyph.png'
    },
    guvi: {
        icon: 'btnglyph.png'
    },
    rspl: {
        icon: 'btnrune.png'
    },
    rre1: {
        icon: 'btnrune.png'
    },
    rre2: {
        icon: 'btnrune.png'
    },
    gomn: {
        icon: 'btnglyph.png'
    },
    rsps: {
        icon: 'btnrune.png'
    },
    rspd: {
        icon: 'btnrune.png'
    },
    rman: {
        icon: 'btnrune.png'
    },
    rma2: {
        icon: 'btnrune.png'
    },
    rres: {
        icon: 'btnrune.png'
    },
    rreb: {
        icon: 'btnrune.png'
    },
    rhe1: {
        icon: 'btnrune.png'
    },
    rhe2: {
        icon: 'btnrune.png'
    },
    rhe3: {
        icon: 'btnrune.png'
    },
    rdis: {
        icon: 'btnrune.png'
    },
    texp: {
        icon: 'btntomebrown.png'
    },
    rwat: {
        icon: 'btnrune.png'
    },
    pclr: {
        icon: 'btnpotionofclarity.png'
    },
    plcl: {
        icon: 'btnlesserclaritypotion.png'
    },
    silk: {
        icon: 'btnspidersilkbroach.png'
    },
    vamp: {
        icon: 'btnpotionofvampirism.png'
    },
    sreg: {
        icon: 'btnscrollofregenerationgreen.png'
    },
    ssan: {
        icon: 'btnstaffofsanctuary.png'
    },
    tcas: {
        icon: 'btntinycastle.png'
    },
    ofr2: {
        icon: 'btnorboffire.png'
    },
    AHbz: {
        icon: 'btnblizzard.png',
        Cool1: '6',
        Cool2: '6',
        Cool3: '6',
        Cool4: '6'
    },
    AHab: {
        icon: 'pasbtnbrilliance.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AHmt: {
        icon: 'btnmassteleport.png',
        Cool1: '20',
        Cool2: '20',
        Cool3: '20',
        Cool4: '20'
    },
    AHwe: {
        icon: 'btnsummonwaterelemental.png',
        Cool1: '20',
        Cool2: '20',
        Cool3: '20',
        Cool4: '20'
    },
    ANst: {
        icon: 'btnstampede.png',
        Cool1: '180',
        Cool2: '180',
        Cool3: '180',
        Cool4: '180'
    },
    ANsg: {
        icon: 'btngrizzlybear.png',
        Cool1: '40',
        Cool2: '40',
        Cool3: '40',
        Cool4: '40'
    },
    ANsq: {
        icon: 'btnquillbeast.png',
        Cool1: '25',
        Cool2: '25',
        Cool3: '25',
        Cool4: '25'
    },
    ANsw: {
        icon: 'btnwareagle.png',
        Cool1: '70',
        Cool2: '70',
        Cool3: '70',
        Cool4: '70'
    },
    AOww: {
        icon: 'btnwhirlwind.png',
        Cool1: '180',
        Cool2: '240',
        Cool3: '240',
        Cool4: '240'
    },
    AOcr: {
        icon: 'pasbtncriticalstrike.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AOmi: {
        icon: 'btnmirrorimage.png',
        Cool1: '3',
        Cool2: '3',
        Cool3: '3',
        Cool4: '3'
    },
    AOwk: {
        icon: 'btnwindwalk.png',
        Cool1: '2',
        Cool2: '2',
        Cool3: '2',
        Cool4: '2'
    },
    AHbn: {
        icon: 'btnbanish.png',
        Cool1: '0',
        Cool2: '0',
        Cool3: '0',
        Cool4: '1'
    },
    AHfs: {
        icon: 'btnwalloffire.png',
        Cool1: '10',
        Cool2: '10',
        Cool3: '10',
        Cool4: '10'
    },
    AHdr: {
        icon: 'btnmanadrain.png',
        Cool1: '6',
        Cool2: '6',
        Cool3: '6',
        Cool4: '6'
    },
    AHpx: {
        icon: 'btnmarkoffire.png',
        Cool1: '180',
        Cool2: '120',
        Cool3: '120',
        Cool4: '120'
    },
    AUcb: {
        icon: 'btncarrionscarabs.png',
        Cool1: '6',
        Cool2: '6',
        Cool3: '6',
        Cool4: '6'
    },
    AUim: {
        icon: 'btnimpale.png',
        Cool1: '9',
        Cool2: '9',
        Cool3: '9',
        Cool4: '9'
    },
    AUls: {
        icon: 'btnlocustswarm.png',
        Cool1: '180',
        Cool2: '180',
        Cool3: '180',
        Cool4: '180'
    },
    AUts: {
        icon: 'pasbtnthornshield.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANba: {
        icon: 'btntheblackarrowonoff.png',
        Cool1: '0',
        Cool2: '0',
        Cool3: '0',
        Cool4: '0'
    },
    ANch: {
        icon: 'btncharm.png',
        Cool1: '45',
        Cool2: '30',
        Cool3: '30',
        Cool4: '30'
    },
    ANdr: {
        icon: 'btnlifedrain.png',
        Cool1: '8',
        Cool2: '8',
        Cool3: '8',
        Cool4: '8'
    },
    ANsi: {
        icon: 'btnsilence.png',
        Cool1: '15',
        Cool2: '15',
        Cool3: '15',
        Cool4: '15'
    },
    AUan: {
        icon: 'btnanimatedead.png',
        Cool1: '180',
        Cool2: '240',
        Cool3: '240',
        Cool4: '240'
    },
    AUdc: {
        icon: 'btndeathcoil.png',
        Cool1: '6',
        Cool2: '6',
        Cool3: '6',
        Cool4: '6'
    },
    AUdp: {
        icon: 'btndeathpact.png',
        Cool1: '15',
        Cool2: '15',
        Cool3: '15',
        Cool4: '15'
    },
    AUau: {
        icon: 'pasbtnunholyaura.png',
        Cool1: '0',
        Cool2: ' - ',
        Cool3: ' - ',
        Cool4: ' - '
    },
    AEev: {
        icon: 'pasbtnevasion.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AEim: {
        icon: 'btnimmolation.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AEmb: {
        icon: 'btnmanaburn.png',
        Cool1: '7',
        Cool2: '6',
        Cool3: '5',
        Cool4: '5'
    },
    AEme: {
        icon: 'btnmetamorphosis.png',
        Cool1: '180',
        Cool2: '180',
        Cool3: '180',
        Cool4: '180'
    },
    AUsl: {
        icon: 'btnsleep.png',
        Cool1: '4',
        Cool2: '4',
        Cool3: '4',
        Cool4: '4'
    },
    AUav: {
        icon: 'pasbtnvampiricaura.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AUcs: {
        icon: 'btncarrionswarm.png',
        Cool1: '10',
        Cool2: '10',
        Cool3: '10',
        Cool4: '10'
    },
    AUin: {
        icon: 'btninfernal.png',
        Cool1: '180',
        Cool2: '180',
        Cool3: '180',
        Cool4: '180'
    },
    AOcl: {
        icon: 'btnchainlightning.png',
        Cool1: '9',
        Cool2: '9',
        Cool3: '9',
        Cool4: '9'
    },
    AOeq: {
        icon: 'btnearthquake.png',
        Cool1: '90',
        Cool2: '90',
        Cool3: '90',
        Cool4: '90'
    },
    AOfs: {
        icon: 'btnfarsight.png',
        Cool1: '30',
        Cool2: '30',
        Cool3: '30',
        Cool4: '30'
    },
    AOsf: {
        icon: 'btnspiritwolf.png',
        Cool1: '30',
        Cool2: '30',
        Cool3: '30',
        Cool4: '30'
    },
    AEer: {
        icon: 'btnentanglingroots.png',
        Cool1: '8',
        Cool2: '8',
        Cool3: '8',
        Cool4: '8'
    },
    AEfn: {
        icon: 'btnent.png',
        Cool1: '20',
        Cool2: '20',
        Cool3: '20',
        Cool4: '20'
    },
    AEah: {
        icon: 'pasbtnthorns.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AEtq: {
        icon: 'btntranquility.png',
        Cool1: '60',
        Cool2: '120',
        Cool3: '120',
        Cool4: '120'
    },
    AUdr: {
        icon: 'btndarkritual.png',
        Cool1: '15',
        Cool2: '15',
        Cool3: '15',
        Cool4: '15'
    },
    AUdd: {
        icon: 'btndeathanddecay.png',
        Cool1: '150',
        Cool2: '150',
        Cool3: '150',
        Cool4: '150'
    },
    AUfa: {
        icon: 'btnfrostarmor.png',
        Cool1: '2',
        Cool2: '2',
        Cool3: '2',
        Cool4: '2'
    },
    AUfu: {
        icon: 'btnfrostarmor.png',
        Cool1: '2',
        Cool2: '2',
        Cool3: '2',
        Cool4: '2'
    },
    AUfn: {
        icon: 'btnglacier.png',
        Cool1: '8',
        Cool2: '8',
        Cool3: '8',
        Cool4: '8'
    },
    AHav: {
        icon: 'btnavatar.png',
        Cool1: '180',
        Cool2: '180',
        Cool3: '180',
        Cool4: '180'
    },
    AHbh: {
        icon: 'pasbtnbash.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AHtb: {
        icon: 'btnstormbolt.png',
        Cool1: '9',
        Cool2: '9',
        Cool3: '9',
        Cool4: '9'
    },
    AHtc: {
        icon: 'btnthunderclap.png',
        Cool1: '6',
        Cool2: '6',
        Cool3: '6',
        Cool4: '6'
    },
    ANfl: {
        icon: 'btnmonsoon.png',
        Cool1: '11',
        Cool2: '11',
        Cool3: '11',
        Cool4: '11'
    },
    ANfa: {
        icon: 'btncoldarrows.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANto: {
        icon: 'btntornado.png',
        Cool1: '120',
        Cool2: '120',
        Cool3: '120',
        Cool4: '120'
    },
    ANms: {
        icon: 'btnneutralmanashield.png',
        Cool1: '10',
        Cool2: '10',
        Cool3: '10',
        Cool4: '10'
    },
    AHad: {
        icon: 'pasbtndevotion.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AHds: {
        icon: 'btndivineintervention.png',
        Cool1: '35',
        Cool2: '50',
        Cool3: '65',
        Cool4: '65'
    },
    AHhb: {
        icon: 'btnholybolt.png',
        Cool1: '5',
        Cool2: '5',
        Cool3: '5',
        Cool4: '5'
    },
    AHre: {
        icon: 'btnresurrection.png',
        Cool1: '240',
        Cool2: '240',
        Cool3: '240',
        Cool4: '240'
    },
    ANbf: {
        icon: 'btnbreathoffire.png',
        Cool1: '10',
        Cool2: '10',
        Cool3: '10',
        Cool4: '10'
    },
    ANdb: {
        icon: 'pasbtndrunkendodge.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANdh: {
        icon: 'btnstrongdrink.png',
        Cool1: '12',
        Cool2: '12',
        Cool3: '12',
        Cool4: '12'
    },
    ANef: {
        icon: 'btnstormearth&fire.png',
        Cool1: '180',
        Cool2: '180',
        Cool3: '180',
        Cool4: '180'
    },
    ANdo: {
        icon: 'btndoom.png',
        Cool1: '120',
        Cool2: '120',
        Cool3: '120',
        Cool4: '120'
    },
    ANht: {
        icon: 'btnhowlofterror.png',
        Cool1: '12',
        Cool2: '12',
        Cool3: '12',
        Cool4: '12'
    },
    ANca: {
        icon: 'pasbtncleavingattack.png',
        Cool1: '0',
        Cool2: '0',
        Cool3: '0',
        Cool4: '0'
    },
    ANrf: {
        icon: 'btnfire.png',
        Cool1: '8',
        Cool2: '8',
        Cool3: '8',
        Cool4: '8'
    },
    AHfa: {
        icon: 'btnsearingarrows.png',
        Cool1: '0',
        Cool2: '0',
        Cool3: '0',
        Cool4: '0'
    },
    AEst: {
        icon: 'btnscout.png',
        Cool1: '20',
        Cool2: '20',
        Cool3: '20',
        Cool4: '20'
    },
    AEsf: {
        icon: 'btnstarfall.png',
        Cool1: '180',
        Cool2: '120',
        Cool3: '120',
        Cool4: '120'
    },
    AEar: {
        icon: 'pasbtntrueshot.png',
        Cool1: '0',
        Cool2: ' - ',
        Cool3: ' - ',
        Cool4: ' - '
    },
    AOae: {
        icon: 'pasbtncommand.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AOre: {
        icon: 'pasbtnreincarnation.png',
        Cool1: '240',
        Cool2: '240',
        Cool3: '240',
        Cool4: '240'
    },
    AOsh: {
        icon: 'btnshockwave.png',
        Cool1: '8',
        Cool2: '8',
        Cool3: '8',
        Cool4: '8'
    },
    AOws: {
        icon: 'btnwarstomp.png',
        Cool1: '6',
        Cool2: '6',
        Cool3: '6',
        Cool4: '6'
    },
    AOhw: {
        icon: 'btnhealingwave.png',
        Cool1: '9',
        Cool2: '9',
        Cool3: '9',
        Cool4: '9'
    },
    AOhx: {
        icon: 'btnhex.png',
        Cool1: '7',
        Cool2: '7',
        Cool3: '7',
        Cool4: '7'
    },
    AOsw: {
        icon: 'btnserpentward.png',
        Cool1: '6.5',
        Cool2: '6.5',
        Cool3: '6.5',
        Cool4: '6.5'
    },
    AOvd: {
        icon: 'btnbigbadvoodoospell.png',
        Cool1: '180',
        Cool2: '180',
        Cool3: '180',
        Cool4: '180'
    },
    AEbl: {
        icon: 'btnblink.png',
        Cool1: '10',
        Cool2: '7',
        Cool3: '4',
        Cool4: '4'
    },
    AEfk: {
        icon: 'btnfanofknives.png',
        Cool1: '9',
        Cool2: '9',
        Cool3: '9',
        Cool4: '9'
    },
    AEsh: {
        icon: 'btnshadowstrike.png',
        Cool1: '8',
        Cool2: '8',
        Cool3: '8',
        Cool4: '8'
    },
    AEsv: {
        icon: 'btnspiritofvengeance.png',
        Cool1: '180',
        Cool2: '20',
        Cool3: '20',
        Cool4: '20'
    },
    ANab: {
        icon: 'btnacidbomb.png',
        Cool1: '12',
        Cool2: '12',
        Cool3: '12',
        Cool4: '12'
    },
    ANcr: {
        icon: 'btnchemicalrage.png',
        Cool1: '30',
        Cool2: '30',
        Cool3: '30',
        Cool4: '30'
    },
    ANhs: {
        icon: 'btnhealingspray.png',
        Cool1: '6',
        Cool2: '6',
        Cool3: '6',
        Cool4: '6'
    },
    ANtm: {
        icon: 'btntransmute.png',
        Cool1: '45',
        Cool2: '45',
        Cool3: '45',
        Cool4: '45'
    },
    ANeg: {
        icon: 'pasbtnengineeringupgrade.png',
        Cool1: '0',
        Cool2: '0',
        Cool3: '0',
        Cool4: '0'
    },
    ANcs: {
        icon: 'btnclusterrockets.png',
        Cool1: '6',
        Cool2: '6',
        Cool3: '6',
        Cool4: '6'
    },
    ANc1: {
        icon: 'btnclusterrockets.png',
        Cool1: '6',
        Cool2: '6',
        Cool3: '6',
        Cool4: '6'
    },
    ANc2: {
        icon: 'btnclusterrockets.png',
        Cool1: '6',
        Cool2: '6',
        Cool3: '6',
        Cool4: '6'
    },
    ANc3: {
        icon: 'btnclusterrockets.png',
        Cool1: '6',
        Cool2: '6',
        Cool3: '6',
        Cool4: '6'
    },
    ANrg: {
        icon: 'btnrobogoblin.png',
        Cool1: '1',
        Cool2: '1',
        Cool3: '1',
        Cool4: '1'
    },
    ANg1: {
        icon: 'btnrobogoblin.png',
        Cool1: '1',
        Cool2: '1',
        Cool3: '1',
        Cool4: '1'
    },
    ANg2: {
        icon: 'btnrobogoblin.png',
        Cool1: '1',
        Cool2: '1',
        Cool3: '1',
        Cool4: '1'
    },
    ANg3: {
        icon: 'btnrobogoblin.png',
        Cool1: '1',
        Cool2: '1',
        Cool3: '1',
        Cool4: '1'
    },
    ANsy: {
        icon: 'btnpocketfactory.png',
        Cool1: '35',
        Cool2: '35',
        Cool3: '35',
        Cool4: '35'
    },
    ANs1: {
        icon: 'btnpocketfactory.png',
        Cool1: '35',
        Cool2: '35',
        Cool3: '35',
        Cool4: '35'
    },
    ANs2: {
        icon: 'btnpocketfactory.png',
        Cool1: '35',
        Cool2: '35',
        Cool3: '35',
        Cool4: '35'
    },
    ANs3: {
        icon: 'btnpocketfactory.png',
        Cool1: '35',
        Cool2: '35',
        Cool3: '35',
        Cool4: '35'
    },
    ANde: {
        icon: 'pasbtndemolish.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANd1: {
        icon: 'pasbtndemolish.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANd2: {
        icon: 'pasbtndemolish.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANd3: {
        icon: 'pasbtndemolish.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANic: {
        icon: 'pasbtnincinerate.png',
        Cool1: '0',
        Cool2: '0',
        Cool3: '0',
        Cool4: '0'
    },
    ANia: {
        icon: 'btnincinerate.png',
        Cool1: '0',
        Cool2: '0',
        Cool3: '0',
        Cool4: '0'
    },
    ANso: {
        icon: 'btnsoulburn.png',
        Cool1: '12',
        Cool2: '12',
        Cool3: '12',
        Cool4: '12'
    },
    ANlm: {
        icon: 'btnlavaspawn.png',
        Cool1: '32',
        Cool2: '32',
        Cool3: '32',
        Cool4: '35'
    },
    ANvc: {
        icon: 'btnvolcano.png',
        Cool1: '180',
        Cool2: '180',
        Cool3: '180',
        Cool4: '180'
    },
    ANin: {
        icon: 'btninfernal.png',
        Cool1: '180',
        Cool2: '180',
        Cool3: '180',
        Cool4: '180'
    },
    SNin: {
        icon: 'btninfernal.png',
        Cool1: '30',
        Cool2: '30',
        Cool3: '30',
        Cool4: '30'
    },
    ANfb: {
        icon: 'btnfirebolt.png',
        Cool1: '8',
        Cool2: '8',
        Cool3: '8',
        Cool4: '8'
    },
    ANfd: {
        icon: 'btncorpseexplode.png',
        Cool1: '15',
        Cool2: '15',
        Cool3: '15',
        Cool4: '15'
    },
    ACfd: {
        icon: 'btncorpseexplode.png',
        Cool1: '5',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACf3: {
        icon: 'btncorpseexplode.png',
        Cool1: '5',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANdp: {
        icon: 'btndizzy.png',
        Cool1: '100',
        Cool2: '100',
        Cool3: '100',
        Cool4: '100'
    },
    ANrc: {
        icon: 'btninfernalstone.png',
        Cool1: '120',
        Cool2: '120',
        Cool3: '120',
        Cool4: '120'
    },
    ANr3: {
        icon: 'btninfernalstone.png',
        Cool1: '120',
        Cool2: '120',
        Cool3: '120',
        Cool4: '120'
    },
    AEsb: {
        icon: 'btnstarfall.png',
        Cool1: '100',
        Cool2: '120',
        Cool3: '120',
        Cool4: '120'
    },
    ANrn: {
        icon: 'pasbtnreincarnation.png',
        Cool1: '240',
        Cool2: '240',
        Cool3: '240',
        Cool4: '240'
    },
    ANdc: {
        icon: 'btnsleep.png',
        Cool1: '0.1',
        Cool2: '0.1',
        Cool3: '0.1',
        Cool4: '0.1'
    },
    SNdc: {
        icon: 'btnsleep.png',
        Cool1: '0.1',
        Cool2: '0.1',
        Cool3: '0.1',
        Cool4: '0.1'
    },
    ANsl: {
        icon: 'btnsleep.png',
        Cool1: '0.1',
        Cool2: '0.1',
        Cool3: '0.1',
        Cool4: '0.1'
    },
    AEIl: {
        icon: 'btnmetamorphosis.png',
        Cool1: '120',
        Cool2: '120',
        Cool3: '120',
        Cool4: '120'
    },
    AEvi: {
        icon: 'btnmetamorphosis.png',
        Cool1: '120',
        Cool2: '120',
        Cool3: '120',
        Cool4: '120'
    },
    SNeq: {
        icon: 'btnearthquake.png',
        Cool1: '90',
        Cool2: '90',
        Cool3: '90',
        Cool4: '90'
    },
    SNdd: {
        icon: 'btndeathanddecay.png',
        Cool1: '150',
        Cool2: '150',
        Cool3: '150',
        Cool4: '150'
    },
    ANmo: {
        icon: 'btnmonsoon.png',
        Cool1: '10',
        Cool2: '10',
        Cool3: '10',
        Cool4: '10'
    },
    AEpa: {
        icon: 'btnsearingarrows.png',
        Cool1: '0',
        Cool2: '0',
        Cool3: '0',
        Cool4: '0'
    },
    ANwm: {
        icon: 'btnmurgultidewarrior.png',
        Cool1: '20',
        Cool2: '20',
        Cool3: '20',
        Cool4: '20'
    },
    AHca: {
        icon: 'btncoldarrows.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANbr: {
        icon: 'btnbattleroar.png',
        Cool1: '25',
        Cool2: '25',
        Cool3: '25',
        Cool4: '25'
    },
    Arsg: {
        icon: 'btngrizzlybear.png',
        Cool1: '30',
        Cool2: '30',
        Cool3: '30',
        Cool4: '30'
    },
    Aamk: {
        icon: 'pasbtnstatup.png',
        Cool1: '0',
        Cool2: '0',
        Cool3: '0',
        Cool4: '0'
    },
    Arsq: {
        icon: 'btnquillbeast.png',
        Cool1: '20',
        Cool2: '20',
        Cool3: '20',
        Cool4: '20'
    },
    Arsp: {
        icon: 'btnstampede.png',
        Cool1: '180',
        Cool2: '180',
        Cool3: '180',
        Cool4: '180'
    },
    ANsb: {
        icon: 'btnstormbolt.png',
        Cool1: '9',
        Cool2: '9',
        Cool3: '9',
        Cool4: '9'
    },
    ANhw: {
        icon: 'btnhealingwave.png',
        Cool1: '9',
        Cool2: '9',
        Cool3: '9',
        Cool4: '9'
    },
    Arsw: {
        icon: 'btnserpentward.png',
        Cool1: '6.5',
        Cool2: '6.5',
        Cool3: '6.5',
        Cool4: '6.5'
    },
    ANhx: {
        icon: 'btnhex.png',
        Cool1: '7',
        Cool2: '7',
        Cool3: '7',
        Cool4: '7'
    },
    AOls: {
        icon: 'btnlocustswarm.png',
        Cool1: '180',
        Cool2: '180',
        Cool3: '180',
        Cool4: '180'
    },
    ANcf: {
        icon: 'btnbreathoffire.png',
        Cool1: '10',
        Cool2: '10',
        Cool3: '10',
        Cool4: '10'
    },
    Acdb: {
        icon: 'pasbtndrunkendodge.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Acdh: {
        icon: 'btnstrongdrink.png',
        Cool1: '12',
        Cool2: '12',
        Cool3: '12',
        Cool4: '12'
    },
    Acef: {
        icon: 'btnstormearth&fire.png',
        Cool1: '180',
        Cool2: '180',
        Cool3: '180',
        Cool4: '180'
    },
    AOr2: {
        icon: 'pasbtncommand.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AOr3: {
        icon: 'pasbtnreincarnation.png',
        Cool1: '240',
        Cool2: '120',
        Cool3: '240',
        Cool4: '240'
    },
    AOs2: {
        icon: 'btnshockwave.png',
        Cool1: '8',
        Cool2: '8',
        Cool3: '8',
        Cool4: '8'
    },
    AOw2: {
        icon: 'btnwarstomp.png',
        Cool1: '6',
        Cool2: '6',
        Cool3: '6',
        Cool4: '6'
    },
    ANcl: {
        icon: 'btndeathpact.png',
        Cool1: '0',
        Cool2: '0',
        Cool3: '0',
        Cool4: '0'
    },
    Aadm: {
        icon: 'btndryaddispelmagic.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Andm: {
        icon: 'btndryaddispelmagic.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACdm: {
        icon: 'btndryaddispelmagic.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACd2: {
        icon: 'btndryaddispelmagic.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aabs: {
        icon: 'btnabsorbmagic.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aaha: {
        icon: 'btngathergold.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANav: {
        icon: 'btnavatar.png',
        Cool1: '180',
        Cool2: '180',
        Cool3: '180',
        Cool4: '180'
    },
    Aall: {
        icon: 'btnselecthero.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aast: {
        icon: 'btnancestralspirit.png',
        Cool1: '30',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACad: {
        icon: 'btnanimatedead.png',
        Cool1: '240',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aams: {
        icon: 'btnantimagicshell.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aam2: {
        icon: 'btnantimagicshell.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACam: {
        icon: 'btnantimagicshell.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACba: {
        icon: 'pasbtnbrilliance.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACac: {
        icon: 'pasbtngnollcommandaura.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACav: {
        icon: 'pasbtndevotion.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    SCae: {
        icon: 'pasbtncommand.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aap1: {
        icon: 'pasbtnplaguecloud.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aap2: {
        icon: 'pasbtnplaguecloud.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aap3: {
        icon: 'pasbtnplaguecloud.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aap4: {
        icon: 'pasbtnplaguecloud.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aoar: {
        icon: 'btnhealingward.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aabr: {
        icon: 'pasbtnregenerationaura.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACat: {
        icon: 'pasbtntrueshot.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aakb: {
        icon: 'pasbtndrum.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aave: {
        icon: 'btndestroyer.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Abof: {
        icon: 'pasbtnfirerocks.png',
        Cool1: '10',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACbn: {
        icon: 'btnbanish.png',
        Cool1: '10',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACbh: {
        icon: 'pasbtnbash.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANbh: {
        icon: 'pasbtnbash.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANb2: {
        icon: 'pasbtnbash.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Abtl: {
        icon: 'btnbattlestations.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Sbtl: {
        icon: 'btnbattlestations.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Abrf: {
        icon: 'btnbearform.png',
        Cool1: '0',
        Cool2: ' - ',
        Cool3: ' - ',
        Cool4: ' - '
    },
    Absk: {
        icon: 'btnberserkfortrolls.png',
        Cool1: '30',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACbk: {
        icon: 'btntheblackarrowonoff.png',
        Cool1: '2',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANbl: {
        icon: 'btnbearblink.png',
        Cool1: '15',
        Cool2: '10',
        Cool3: '1',
        Cool4: '1'
    },
    ACbz: {
        icon: 'btnblizzard.png',
        Cool1: '12',
        Cool2: '-',
        Cool3: ' - ',
        Cool4: ' - '
    },
    Ablo: {
        icon: 'btnbloodlust.png',
        Cool1: '1',
        Cool2: ' - ',
        Cool3: ' - ',
        Cool4: ' - '
    },
    ACbl: {
        icon: 'btnbloodlust.png',
        Cool1: '8',
        Cool2: ' - ',
        Cool3: ' - ',
        Cool4: ' - '
    },
    ACbb: {
        icon: 'btnbloodlust.png',
        Cool1: '8',
        Cool2: ' - ',
        Cool3: ' - ',
        Cool4: ' - '
    },
    ACbc: {
        icon: 'btnfireforthecannon.png',
        Cool1: '10',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACbf: {
        icon: 'btnbreathoffrost.png',
        Cool1: '10',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANbu: {
        icon: 'btnbasicstruct.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AHbu: {
        icon: 'btnhumanbuild.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AObu: {
        icon: 'btnbasicstruct.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AEbu: {
        icon: 'btnnightelfbuild.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AUbu: {
        icon: 'btnscourgebuild.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AGbu: {
        icon: 'btnbasicstruct.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Abur: {
        icon: 'btncryptfiendburrow.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Abu2: {
        icon: 'btncryptfiendburrow.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Abu3: {
        icon: 'btncryptfiendburrow.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Abu5: {
        icon: 'btncryptfiendburrow.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Acan: {
        icon: 'btncannibalize.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Acn2: {
        icon: 'btncannibalize.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACcn: {
        icon: 'btncannibalize.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aenc: {
        icon: 'btnload.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACca: {
        icon: 'btncarrionswarm.png',
        Cool1: '8',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACcv: {
        icon: 'btncrushingwave.png',
        Cool1: '8',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACc2: {
        icon: 'btncrushingwave.png',
        Cool1: '8',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACc3: {
        icon: 'btncrushingwave.png',
        Cool1: '10',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACcl: {
        icon: 'btnchainlightning.png',
        Cool1: '9',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Ache: {
        icon: 'btnwandofneutralization.png',
        Cool1: '15',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACch: {
        icon: 'btncharm.png',
        Cool1: '20',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACce: {
        icon: 'pasbtncleavingattack.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aclf: {
        icon: 'btncloudoffog.png',
        Cool1: '20',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACcw: {
        icon: 'btncoldarrows.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Acmg: {
        icon: 'btncontrolmagic.png',
        Cool1: '5',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Acpf: {
        icon: 'btnspiritwalker.png',
        Cool1: '30',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Acor: {
        icon: 'pasbtncorrosivebreath.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Acoa: {
        icon: 'btnhippogriffrider.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Acoh: {
        icon: 'btnhippogriffrider.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aco2: {
        icon: 'btnhippogriffrider.png',
        Cool1: '30',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aco3: {
        icon: 'btnhippogriffrider.png',
        Cool1: '30',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACsp: {
        icon: 'btnsleep.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Acri: {
        icon: 'btncripple.png',
        Cool1: '10',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Scri: {
        icon: 'btncripple.png',
        Cool1: '10',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACcr: {
        icon: 'btncripple.png',
        Cool1: '10',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACct: {
        icon: 'pasbtncriticalstrike.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Acrs: {
        icon: 'btncurse.png',
        Cool1: '1',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACcs: {
        icon: 'btncurse.png',
        Cool1: '8',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Acyc: {
        icon: 'btncyclone.png',
        Cool1: '5',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Acny: {
        icon: 'btncyclone.png',
        Cool1: '5',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACcy: {
        icon: 'btncyclone.png',
        Cool1: '8',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    SCc1: {
        icon: 'btncyclone.png',
        Cool1: '8',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACdc: {
        icon: 'btndeathcoil.png',
        Cool1: '8',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Adec: {
        icon: 'btnarcher.png',
        Cool1: '30',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Adef: {
        icon: 'btndefend.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Atru: {
        icon: 'pasbtnshadetruesight.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Adtg: {
        icon: 'pasbtnscout.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANtr: {
        icon: 'pasbtnscout.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Agyv: {
        icon: 'pasbtnflyingmachinetruesight.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Adts: {
        icon: 'pasbtnmagicalsentry.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Adtn: {
        icon: 'btnwispsplode.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Adev: {
        icon: 'btndevour.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACdv: {
        icon: 'btnreddragondevour.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Advm: {
        icon: 'btndevourmagic.png',
        Cool1: '7',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACde: {
        icon: 'btndevourmagic.png',
        Cool1: '6',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Adch: {
        icon: 'btndisenchant.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Adcn: {
        icon: 'btndisenchant.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Adis: {
        icon: 'btndispelmagic.png',
        Cool1: '5',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Adsm: {
        icon: 'btndispelmagic.png',
        Cool1: '8',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACds: {
        icon: 'btndivineintervention.png',
        Cool1: '60',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACdr: {
        icon: 'btnlifedrain.png',
        Cool1: '8',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Adri: {
        icon: 'btnunload.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Adro: {
        icon: 'btnunload.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Sdro: {
        icon: 'btnunload.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aeat: {
        icon: 'btneattree.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aegr: {
        icon: 'pasbtnelunesblessing.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANen: {
        icon: 'btnensnare.png',
        Cool1: '15',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aens: {
        icon: 'btnensnare.png',
        Cool1: '15',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACen: {
        icon: 'btnensnare.png',
        Cool1: '15',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aent: {
        icon: 'btngoldmine.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aenr: {
        icon: 'btnentanglingroots.png',
        Cool1: '8',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aenw: {
        icon: 'btnentanglingroots.png',
        Cool1: '8',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aetl: {
        icon: 'btntemp.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aetf: {
        icon: 'btnetherealform.png',
        Cool1: '30',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACev: {
        icon: 'pasbtnevasion.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACes: {
        icon: 'pasbtnevasion.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aexh: {
        icon: 'pasbtnexhumecorpses.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANfy: {
        icon: 'btnpocketfactory.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Afae: {
        icon: 'btnfaeriefire.png',
        Cool1: '1',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Afa2: {
        icon: 'btnfaeriefire.png',
        Cool1: '1',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACff: {
        icon: 'btnfaeriefire.png',
        Cool1: '8',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Afbk: {
        icon: 'pasbtnfeedback.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Afbt: {
        icon: 'pasbtnfeedback.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Afbb: {
        icon: 'pasbtnfeedback.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACsf: {
        icon: 'btnspiritwolf.png',
        Cool1: '15',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACs9: {
        icon: 'btnrazorback.png',
        Cool1: '15',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACs8: {
        icon: 'btnfelhound.png',
        Cool1: '20',
        Cool2: '20',
        Cool3: '20',
        Cool4: '20'
    },
    ACs7: {
        icon: 'btnspiritwolf.png',
        Cool1: '15',
        Cool2: '15',
        Cool3: '15',
        Cool4: '15'
    },
    Afod: {
        icon: 'btncorpseexplode.png',
        Cool1: '15',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Awfb: {
        icon: 'btnfirebolt.png',
        Cool1: '8',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACfb: {
        icon: 'btnfirebolt.png',
        Cool1: '8',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aflk: {
        icon: 'pasbtnflakcannons.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Afla: {
        icon: 'btnflare.png',
        Cool1: '120',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACfs: {
        icon: 'btnwalloffire.png',
        Cool1: '10',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANfs: {
        icon: 'btnwalloffire.png',
        Cool1: '10',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACfr: {
        icon: 'btnent.png',
        Cool1: '20',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACfl: {
        icon: 'btnmonsoon.png',
        Cool1: '11',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Afsh: {
        icon: 'pasbtnfragmentationbombs.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Afrz: {
        icon: 'pasbtnfreezingbreath.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Afzy: {
        icon: 'btnbloodlust.png',
        Cool1: '30',
        Cool2: ' - ',
        Cool3: ' - ',
        Cool4: ' - '
    },
    ACfa: {
        icon: 'btnfrostarmor.png',
        Cool1: '5',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACf2: {
        icon: 'btnfrostarmor.png',
        Cool1: '5',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACfu: {
        icon: 'btnfrostarmor.png',
        Cool1: '2',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Afra: {
        icon: 'pasbtnfrost.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Afr2: {
        icon: 'pasbtnfrost.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACfn: {
        icon: 'btnglacier.png',
        Cool1: '8',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACcb: {
        icon: 'btnfrostbolt.png',
        Cool1: '9',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Agra: {
        icon: 'btngrabtree.png',
        Cool1: '5',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Agyb: {
        icon: 'pasbtnhumanartilleryupone.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Assk: {
        icon: 'pasbtnhardenedskin.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Ansk: {
        icon: 'pasbtnhardenedskin.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Ahar: {
        icon: 'btngathergold.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANha: {
        icon: 'btngathergold.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Ahrl: {
        icon: 'btngathergold.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Ahr3: {
        icon: 'btngathergold.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Ahr2: {
        icon: 'btngathergold.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Ahea: {
        icon: 'btnheal.png',
        Cool1: '1',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Anhe: {
        icon: 'btnheal.png',
        Cool1: '1',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Anh1: {
        icon: 'btnheal.png',
        Cool1: '1',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Anh2: {
        icon: 'btnheal.png',
        Cool1: '1',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Ahwd: {
        icon: 'btnhealingward.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AChw: {
        icon: 'btnhealingward.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Ahnl: {
        icon: 'btnantimagicshell.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AChx: {
        icon: 'btnhex.png',
        Cool1: '17',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Acht: {
        icon: 'btnhowlofterror.png',
        Cool1: '12',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACim: {
        icon: 'btnimmolation.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACmp: {
        icon: 'btnimpale.png',
        Cool1: '11',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Ainf: {
        icon: 'btninnerfire.png',
        Cool1: '1',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACif: {
        icon: 'btninnerfire.png',
        Cool1: '5',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aivs: {
        icon: 'btninvisibility.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Apak: {
        icon: 'btnpackbeast.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aion: {
        icon: 'btnpackbeast.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aihn: {
        icon: 'btnpackbeast.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aien: {
        icon: 'btnpackbeast.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aiun: {
        icon: 'btnpackbeast.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Alsh: {
        icon: 'btnlightningshield.png',
        Cool1: '3',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACls: {
        icon: 'btnlightningshield.png',
        Cool1: '8',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aliq: {
        icon: 'pasbtnliquidfire.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aloa: {
        icon: 'btnload.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Sloa: {
        icon: 'btnload.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Slo2: {
        icon: 'btnload.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Slo3: {
        icon: 'btnload.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Amdf: {
        icon: 'btnspellbreakermagicdefend.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Amim: {
        icon: 'pasbtnmagicimmunity.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACmi: {
        icon: 'pasbtngenericspellimmunity.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACm2: {
        icon: 'pasbtngenericspellimmunity.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACm3: {
        icon: 'pasbtngenericspellimmunity.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Amls: {
        icon: 'btnmagiclariet.png',
        Cool1: '30',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Ambt: {
        icon: 'btnmanarecharge.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Amb2: {
        icon: 'btnreplenishmana.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Amnb: {
        icon: 'btnmanaburn.png',
        Cool1: '9',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Ambd: {
        icon: 'btnmanaburn.png',
        Cool1: '9',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Ambb: {
        icon: 'btnmanaburn.png',
        Cool1: '9',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Amfl: {
        icon: 'btnmanaflare.png',
        Cool1: '20',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACmf: {
        icon: 'btnneutralmanashield.png',
        Cool1: '10',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Amed: {
        icon: 'btnundeadunload.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Amel: {
        icon: 'btnundeadload.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Amil: {
        icon: 'btncalltoarms.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Amic: {
        icon: 'btncalltoarms.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANmr: {
        icon: 'btntemp.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACmo: {
        icon: 'btnmonsoon.png',
        Cool1: '10',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Amgl: {
        icon: 'pasbtnupgrademoonglaive.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Amgr: {
        icon: 'pasbtnupgrademoonglaive.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aneu: {
        icon: 'btnselecthero.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Ane2: {
        icon: 'btnselecthero.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Andt: {
        icon: 'btnreveal.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANre: {
        icon: 'btnheal.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACnr: {
        icon: 'btnheal.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AAns: {
        icon: 'btnreturngoods.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Afak: {
        icon: 'btnorbofdeath.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANak: {
        icon: 'btnquillspray.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANpa: {
        icon: 'btnparasite.png',
        Cool1: '5',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACpa: {
        icon: 'btnparasite.png',
        Cool1: '5',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANpi: {
        icon: 'pasbtnimmolation.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Apmf: {
        icon: 'pasbtnimmolation.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Apig: {
        icon: 'pasbtnimmolation.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Apsh: {
        icon: 'btnphaseshift.png',
        Cool1: '6.5',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Apxf: {
        icon: 'btnmarkoffire.png',
        Cool1: '0.5',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Apts: {
        icon: 'pasbtnplaguecloud.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Apoi: {
        icon: 'pasbtnpoisonsting.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aply: {
        icon: 'btnpolymorph.png',
        Cool1: '3',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACpy: {
        icon: 'btnpolymorph.png',
        Cool1: '10',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Apos: {
        icon: 'btnpossession.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACps: {
        icon: 'btnpossession.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aps2: {
        icon: 'btnpossession.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Awar: {
        icon: 'pasbtnsmash.png',
        Cool1: '0',
        Cool2: '0',
        Cool3: '-',
        Cool4: '-'
    },
    ACpv: {
        icon: 'pasbtnseagiantpulverize.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aprg: {
        icon: 'btnpurge.png',
        Cool1: '1',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Apg2: {
        icon: 'btnpurge.png',
        Cool1: '1',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACpu: {
        icon: 'btnpurge.png',
        Cool1: '5',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACrf: {
        icon: 'btnfire.png',
        Cool1: '12',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACrg: {
        icon: 'btnfire.png',
        Cool1: '12',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Arai: {
        icon: 'btnraisedead.png',
        Cool1: '8',
        Cool2: '8',
        Cool3: '-',
        Cool4: '-'
    },
    ACrd: {
        icon: 'btnraisedead.png',
        Cool1: '12',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ARal: {
        icon: 'btnrallypoint.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Arav: {
        icon: 'btnravenform.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Amrf: {
        icon: 'btnravenform.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACrn: {
        icon: 'pasbtnreincarnation.png',
        Cool1: '240',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANr2: {
        icon: 'pasbtnreincarnation.png',
        Cool1: '240',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Arej: {
        icon: 'btnrejuvenation.png',
        Cool1: '1',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACrj: {
        icon: 'btnrejuvenation.png',
        Cool1: '5',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACr2: {
        icon: 'btnrejuvenation.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aren: {
        icon: 'btnwispheal.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Ahrp: {
        icon: 'btnrepair.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Arep: {
        icon: 'btnrepair.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Arpb: {
        icon: 'btnreplenishmana.png',
        Cool1: '1',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Arpl: {
        icon: 'btnreplenishhealth.png',
        Cool1: '1',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Arpm: {
        icon: 'btnreplenishmana.png',
        Cool1: '1',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Arsk: {
        icon: 'pasbtnresistantskin.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACrk: {
        icon: 'pasbtnthickfur.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACsk: {
        icon: 'pasbtnthickfur.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Arst: {
        icon: 'btnrepair.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AHta: {
        icon: 'btnreveal.png',
        Cool1: '180',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aroa: {
        icon: 'btnbattleroar.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Ara2: {
        icon: 'btnbattleroar.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACr1: {
        icon: 'btnbattleroar.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACro: {
        icon: 'btnbattleroar.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aroc: {
        icon: 'pasbtnscatterrockets.png',
        Cool1: '2',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aro1: {
        icon: 'btnroot.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aro2: {
        icon: 'btnroot.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Asac: {
        icon: 'btnsacrifice.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Asal: {
        icon: 'pasbtnpillage.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Alam: {
        icon: 'btnsacrifice.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACsa: {
        icon: 'btnsearingarrows.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Asds: {
        icon: 'btnselfdestruct.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Asdg: {
        icon: 'btnselfdestruct.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Asd2: {
        icon: 'btnselfdestruct.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Asd3: {
        icon: 'btnselfdestruct.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Asid: {
        icon: 'btnsell.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Asud: {
        icon: 'btnhire.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aesn: {
        icon: 'btnsentinel.png',
        Cool1: '120',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aesr: {
        icon: 'btnsentinel.png',
        Cool1: '60',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aeye: {
        icon: 'btnsentryward.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACtn: {
        icon: 'btntentacle.png',
        Cool1: '1',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Ashm: {
        icon: 'btnambush.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIhm: {
        icon: 'btnambushday.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Sshm: {
        icon: 'btnambush.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Ahid: {
        icon: 'btnambush.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACss: {
        icon: 'btnshadowstrike.png',
        Cool1: '8',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACsh: {
        icon: 'btnshockwave.png',
        Cool1: '8',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACst: {
        icon: 'btnshockwave.png',
        Cool1: '8',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANsh: {
        icon: 'btnshockwave.png',
        Cool1: '8',
        Cool2: '8',
        Cool3: '8',
        Cool4: '8'
    },
    ACsi: {
        icon: 'btnsilence.png',
        Cool1: '20',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACsm: {
        icon: 'btnmanadrain.png',
        Cool1: '8',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACsl: {
        icon: 'btnsleep.png',
        Cool1: '6',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aslo: {
        icon: 'btnslow.png',
        Cool1: '1',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACsw: {
        icon: 'btnslow.png',
        Cool1: '8',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aspo: {
        icon: 'pasbtnslowpoison.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Asps: {
        icon: 'btnspellsteal.png',
        Cool1: '6',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aspl: {
        icon: 'btnspiritlink.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Astd: {
        icon: 'btnbacktowork.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Asta: {
        icon: 'btnstasistrap.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Astn: {
        icon: 'btnstoneform.png',
        Cool1: '30',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Asth: {
        icon: 'pasbtnstormhammer.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Asb1: {
        icon: 'btnnagaburrow.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Asb2: {
        icon: 'btnnagaburrow.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Asb3: {
        icon: 'btnnagaburrow.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aslp: {
        icon: 'btnlobstrokkred.png',
        Cool1: '30',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACwe: {
        icon: 'btnseaelemental.png',
        Cool1: '30',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Atau: {
        icon: 'btntaunt.png',
        Cool1: '16',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANta: {
        icon: 'btnpandataunt.png',
        Cool1: '16',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANth: {
        icon: 'pasbtnthornshield.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANt2: {
        icon: 'pasbtnthornshield.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACah: {
        icon: 'pasbtnthorns.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACtb: {
        icon: 'btngolemstormbolt.png',
        Cool1: '8',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACtc: {
        icon: 'btngolemthunderclap.png',
        Cool1: '6',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACt2: {
        icon: 'btngolemthunderclap.png',
        Cool1: '6',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACua: {
        icon: 'pasbtnunholyaura.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Auhf: {
        icon: 'btnunholyfrenzy.png',
        Cool1: '1',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Suhf: {
        icon: 'btnunholyfrenzy.png',
        Cool1: '3',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACuf: {
        icon: 'btnunholyfrenzy.png',
        Cool1: '5',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Auuf: {
        icon: 'btnunholyfrenzy.png',
        Cool1: '5',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Auco: {
        icon: 'btnunstableconcoction.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Auns: {
        icon: 'btnunsummonbuilding.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    SCva: {
        icon: 'btnfrostmourne.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACvp: {
        icon: 'pasbtnvampiricaura.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Avng: {
        icon: 'btnavengingwatcher.png',
        Cool1: '2',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Awrs: {
        icon: 'btnwarstomp.png',
        Cool1: '6',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Awrg: {
        icon: 'btnseagiantwarstomp.png',
        Cool1: '6',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Awrh: {
        icon: 'btnhydrawarstomp.png',
        Cool1: '6',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANwk: {
        icon: 'btnwindwalk.png',
        Cool1: '7',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Awha: {
        icon: 'btngathergold.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Awh2: {
        icon: 'btngathergold.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aven: {
        icon: 'pasbtnenvenomedspear.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACvs: {
        icon: 'pasbtnenvenomedspear.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aweb: {
        icon: 'btnweb.png',
        Cool1: '12',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ACwb: {
        icon: 'btnweb.png',
        Cool1: '12',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIa1: {
        icon: 'btnringpurple.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIa6: {
        icon: 'btnboots.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIx5: {
        icon: 'btnhelmutpurple.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIx1: {
        icon: 'btngoldring.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIx2: {
        icon: 'btncirclet.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIs1: {
        icon: 'btnhammer.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIs6: {
        icon: 'btnbelt.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIi1: {
        icon: 'btnshadowpact.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIi6: {
        icon: 'btnrobeofthemagi.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIxm: {
        icon: 'btntomered.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIam: {
        icon: 'btntome.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIim: {
        icon: 'btntome.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIsm: {
        icon: 'btntome.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIgm: {
        icon: 'btntome.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AItm: {
        icon: 'btntome.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AInm: {
        icon: 'btntome.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIaa: {
        icon: 'btntomered.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIat: {
        icon: 'btnclawsofattack.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIt6: {
        icon: 'btnclawsofattack.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIt9: {
        icon: 'btnclawsofattack.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AItc: {
        icon: 'btnclawsofattack.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AItf: {
        icon: 'btnclawsofattack.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AItg: {
        icon: 'btnclawsofattack.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIth: {
        icon: 'btnclawsofattack.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIti: {
        icon: 'btnclawsofattack.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AItj: {
        icon: 'btnclawsofattack.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AItk: {
        icon: 'btnclawsofattack.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AItl: {
        icon: 'btnclawsofattack.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AItn: {
        icon: 'btnclawsofattack.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIva: {
        icon: 'btnmaskofdeath.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIbk: {
        icon: 'btnblink.png',
        Cool1: '30',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIbl: {
        icon: 'btntinycastle.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIbg: {
        icon: 'btntownhall.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIbt: {
        icon: 'btnhumanwatchtower.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIbb: {
        icon: 'btnblacksmith.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIbf: {
        icon: 'btnfarm.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIbr: {
        icon: 'btnhumanlumbermill.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIbs: {
        icon: 'btnhumanbarracks.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIbh: {
        icon: 'btnaltarofkings.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIcy: {
        icon: 'btncyclone.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AId2: {
        icon: 'btnringgreen.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AId3: {
        icon: 'btnringgreen.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AId4: {
        icon: 'btnringgreen.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIem: {
        icon: 'btntomebrown.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIe2: {
        icon: 'btnmanual3.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIfd: {
        icon: 'btnreddragon.png',
        Cool1: '20',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIff: {
        icon: 'btnfurbolg.png',
        Cool1: '20',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIfr: {
        icon: 'btnrockgolem.png',
        Cool1: '20',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIfu: {
        icon: 'btndoomguard.png',
        Cool1: '20',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIfh: {
        icon: 'btnfelhound.png',
        Cool1: '20',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIfs: {
        icon: 'btnskeletonwarrior.png',
        Cool1: '20',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIir: {
        icon: 'btniceshard.png',
        Cool1: '20',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIuw: {
        icon: 'btnamuletofthewild.png',
        Cool1: '20',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIfm: {
        icon: 'btnhumancaptureflag.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIfo: {
        icon: 'btnorccaptureflag.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIfn: {
        icon: 'btnnightelfcaptureflag.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIfe: {
        icon: 'btnundeadcaptureflag.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIfx: {
        icon: 'btnorcbattlestandard.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIfa: {
        icon: 'btnflare.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIin: {
        icon: 'btninfernal.png',
        Cool1: '20',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIlm: {
        icon: 'btntomered.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIlp: {
        icon: 'btnpurge.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIl2: {
        icon: 'btnperiapt1.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIms: {
        icon: 'btnbootsofspeed.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANbs: {
        icon: 'btntheblackarrow.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIdf: {
        icon: 'btnorbofdarkness.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIcb: {
        icon: 'btnorbofcorruption.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIdn: {
        icon: 'btnorbofdarkness.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIfb: {
        icon: 'btnorboffire.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIgd: {
        icon: 'btnorboffire.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIob: {
        icon: 'btnorboffrost.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIll: {
        icon: 'btnorboflightning.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIlb: {
        icon: 'btnorboflightning.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIsb: {
        icon: 'btnorbofslowness.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIpb: {
        icon: 'btnorbofvenom.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Apo2: {
        icon: 'pasbtnpoisonsting.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AInd: {
        icon: 'btnwandskull.png',
        Cool1: '90',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Arel: {
        icon: 'btnringskull.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Arll: {
        icon: 'btnhealthstone.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIsi: {
        icon: 'btntelescope.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIos: {
        icon: 'btnslow.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIso: {
        icon: 'btnsoulgem.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Asou: {
        icon: 'btnusedsoulgem.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIcf: {
        icon: 'btncloakofflames.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIco: {
        icon: 'btnscepterofmastery.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIdm: {
        icon: 'btngoblinlandmine.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIda: {
        icon: 'btnscroll.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIdb: {
        icon: 'btnscroll.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIta: {
        icon: 'btncrystalball.png',
        Cool1: '60',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIdi: {
        icon: 'btnwandskull.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIds: {
        icon: 'btnstaffofnegation.png',
        Cool1: '7.5',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    APdi: {
        icon: 'btnrune.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIh1: {
        icon: 'btnpotiongreensmall.png',
        Cool1: '20',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIh2: {
        icon: 'btnpotiongreen.png',
        Cool1: '40',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIh3: {
        icon: 'btnheartofaszune.png',
        Cool1: '60',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIha: {
        icon: 'btnscrolloftownportal.png',
        Cool1: '25',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIhb: {
        icon: 'btnscrolloftownportal.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    APh1: {
        icon: 'btnscrolloftownportal.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    APh2: {
        icon: 'btnscrolloftownportal.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    APh3: {
        icon: 'btnscrolloftownportal.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIhw: {
        icon: 'btnhealingward.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIsw: {
        icon: 'btnsentryward.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIil: {
        icon: 'btnwand.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIv1: {
        icon: 'btnlesserinvisibility.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIv2: {
        icon: 'btngreaterinvisibility.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIvu: {
        icon: 'btngreaterinvulneralbility.png',
        Cool1: '45',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIvl: {
        icon: 'btnlesserinvulneralbility.png',
        Cool1: '45',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIm1: {
        icon: 'btnpotionbluesmall.png',
        Cool1: '20',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIm2: {
        icon: 'btnpotionbluebig.png',
        Cool1: '40',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AImr: {
        icon: 'btnscrollofprotection.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    APmr: {
        icon: 'btnscrollofprotection.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    APmg: {
        icon: 'btnscrollofprotection.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIpm: {
        icon: 'btngoblinlandmine.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIrt: {
        icon: 'btnamulet.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIrm: {
        icon: 'btnsobimask.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIrc: {
        icon: 'btnreincarnation.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIre: {
        icon: 'btnpotionred.png',
        Cool1: '40',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIra: {
        icon: 'btnscrollofhealing.png',
        Cool1: '40',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    APra: {
        icon: 'btnscrollofhealing.png',
        Cool1: '40',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIsp: {
        icon: 'btnpotionred.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIsa: {
        icon: 'btnscrollofhaste.png',
        Cool1: '60',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    APsa: {
        icon: 'btnscrollofhaste.png',
        Cool1: '60',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AItp: {
        icon: 'btnscrolluber.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIad: {
        icon: 'btndevotion.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIcd: {
        icon: 'pasbtngnollcommandaura.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIwd: {
        icon: 'pasbtndrum.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIba: {
        icon: 'btnbrilliance.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIav: {
        icon: 'btnvampiricaura.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIar: {
        icon: 'btntrueshot.png',
        Cool1: '0',
        Cool2: ' - ',
        Cool3: ' - ',
        Cool4: ' - '
    },
    AIae: {
        icon: 'btncommand.png',
        Cool1: '0',
        Cool2: ' - ',
        Cool3: ' - ',
        Cool4: ' - '
    },
    AIau: {
        icon: 'btnunholyaura.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIuv: {
        icon: 'btntelescope.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIls: {
        icon: 'btnlightningshield.png',
        Cool1: '10',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIxs: {
        icon: 'btnsnazzypotion.png',
        Cool1: '30',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIan: {
        icon: 'btnanimatedead.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIrs: {
        icon: 'btnresurrection.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIrr: {
        icon: 'btnbattleroar.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIev: {
        icon: 'btnevasion.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AImx: {
        icon: 'btnnecklace.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AImh: {
        icon: 'btnmanual.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIsx: {
        icon: 'btnglove.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIs2: {
        icon: 'btngauntletsofogrepower.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIrl: {
        icon: 'btnhealingsalve.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIpr: {
        icon: 'btnpotionofclarity.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIsl: {
        icon: 'btnscrollofregenerationgreen.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIpl: {
        icon: 'btnlesserclaritypotion.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIp1: {
        icon: 'btnminorrejuvpotion.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIp2: {
        icon: 'btnlesserrejuvpotion.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIp3: {
        icon: 'btnrejuvpotion.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIp4: {
        icon: 'btngreaterrejuvpotion.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIp5: {
        icon: 'btnlesserrejuvscroll.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIp6: {
        icon: 'btngreaterrejuvscroll.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIgo: {
        icon: 'btnchestofgold.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIlu: {
        icon: 'btnbundleoflumber.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIrv: {
        icon: 'btnpotionofomniscience.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIdc: {
        icon: 'btnwandofneutralization.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIwb: {
        icon: 'btnweb.png',
        Cool1: '20',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AImo: {
        icon: 'btnmonsterlure.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIct: {
        icon: 'btnmoonstone.png',
        Cool1: '70',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIri: {
        icon: 'btnphilosophersstone.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIsr: {
        icon: 'btnrunedbracers.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Ablp: {
        icon: 'btnsacrificialskull.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIpv: {
        icon: 'btnpotionofvampirism.png',
        Cool1: '60',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aste: {
        icon: 'btnwandofmanasteal.png',
        Cool1: '10',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Amec: {
        icon: 'btnmechanicalcritter.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Ashs: {
        icon: 'btnwandofshadowsight.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANpr: {
        icon: 'btnstaffofpreservation.png',
        Cool1: '30',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANsa: {
        icon: 'btnstaffofsanctuary.png',
        Cool1: '45',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANss: {
        icon: 'btnspellshieldamulet.png',
        Cool1: '40',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    ANse: {
        icon: 'btnspellshieldamulet.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aret: {
        icon: 'btntomeofretraining.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AImt: {
        icon: 'btnstaffofteleportation.png',
        Cool1: '90',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aspb: {
        icon: 'btnspellbookbls.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIrd: {
        icon: 'btnraisedead.png',
        Cool1: '22',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AItb: {
        icon: 'btndustofappearance.png',
        Cool1: '20',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIdv: {
        icon: 'btnpotionofdivinity.png',
        Cool1: '60',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIse: {
        icon: 'btnstaffofsilence.png',
        Cool1: '20',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIpg: {
        icon: 'btnentrapmentward.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIps: {
        icon: 'btnentrapmentward.png',
        Cool1: '8',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIfg: {
        icon: 'btncloudoffog.png',
        Cool1: '15',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    APrl: {
        icon: 'btnresurrection.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    APrr: {
        icon: 'btnresurrection.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIrb: {
        icon: 'btnrune.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Aspp: {
        icon: 'btnspiritlink.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AUds: {
        icon: 'btndarksummoning.png',
        Cool1: '90',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    APwt: {
        icon: 'btnsentryward.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIuf: {
        icon: 'btnunholyfrenzy.png',
        Cool1: '1',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIcm: {
        icon: 'btnspellsteal.png',
        Cool1: '5',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIfz: {
        icon: 'btncorpseexplode.png',
        Cool1: '30',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIdp: {
        icon: 'btndeathpact.png',
        Cool1: '15',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIpx: {
        icon: 'btntomered.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIdd: {
        icon: 'btndefend.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIhx: {
        icon: 'btnheal.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIrx: {
        icon: 'btnresurrection.png',
        Cool1: '240',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIbx: {
        icon: 'pasbtnbash.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AItx: {
        icon: 'btnclawsofattack.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIwm: {
        icon: 'btnmurgultidewarrior.png',
        Cool1: '20',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIsh: {
        icon: 'btnspiritwolf.png',
        Cool1: '60',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIgx: {
        icon: 'btnhealingward.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIhl: {
        icon: 'btnholybolt.png',
        Cool1: '5',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIsz: {
        icon: 'btndaggerofescape.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIpz: {
        icon: 'btnpenguin.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIfw: {
        icon: 'btnorboffire.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIft: {
        icon: 'btnorboffrost.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIlx: {
        icon: 'btnorboflightning.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIcs: {
        icon: 'pasbtncriticalstrike.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIcl: {
        icon: 'btnchainlightning.png',
        Cool1: '9',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIxk: {
        icon: 'btnberserkfortrolls.png',
        Cool1: '30',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIdg: {
        icon: 'btnsacrificialdagger.png',
        Cool1: '20',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIg2: {
        icon: 'btnsacrificialdagger.png',
        Cool1: '5',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    AIf2: {
        icon: 'btnorboffire.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Ahsb: {
        icon: 'pasbtnsunderingblades.png',
        Cool1: '0',
        Cool2: '-',
        Cool3: '-',
        Cool4: '-'
    },
    Rhme: {
        icon: 'btnsteelmelee.png'
    },
    Rhra: {
        icon: 'btnhumanmissileupone.png'
    },
    Rhhb: {
        icon: 'btnstormhammer.png'
    },
    Rhar: {
        icon: 'btnhumanarmorupone.png'
    },
    Rhgb: {
        icon: 'btnhumanartilleryupone.png'
    },
    Rhac: {
        icon: 'btnstonearchitecture.png'
    },
    Rhde: {
        icon: 'btndefend.png'
    },
    Rhan: {
        icon: 'btnanimalwartraining.png'
    },
    Rhpt: {
        icon: 'btnpriestadept.png'
    },
    Rhst: {
        icon: 'btnsorceressadept.png'
    },
    Rhla: {
        icon: 'btnleatherupgradeone.png'
    },
    Rhri: {
        icon: 'btndwarvenlongrifle.png'
    },
    Rhlh: {
        icon: 'btnhumanlumberupgrade1.png'
    },
    Rhse: {
        icon: 'btnmagicalsentry.png'
    },
    Rhfl: {
        icon: 'btnflare.png'
    },
    Rhss: {
        icon: 'btncontrolmagic.png'
    },
    Rhrt: {
        icon: 'btnscatterrockets.png'
    },
    Rhpm: {
        icon: 'btnpackbeast.png'
    },
    Rhfc: {
        icon: 'btnflakcannons.png'
    },
    Rhfs: {
        icon: 'btnfragmentationbombs.png'
    },
    Rhcd: {
        icon: 'btncloudoffog.png'
    },
    Rhsb: {
        icon: 'btnsunderingblades.png'
    },
    Rome: {
        icon: 'btnorcmeleeupone.png'
    },
    Rora: {
        icon: 'btnsteelranged.png'
    },
    Roar: {
        icon: 'btnsteelarmor.png'
    },
    Rwdm: {
        icon: 'btndrum.png'
    },
    Ropg: {
        icon: 'btnpillage.png'
    },
    Robs: {
        icon: 'btnberserk.png'
    },
    Rows: {
        icon: 'btnsmash.png'
    },
    Roen: {
        icon: 'btnensnare.png'
    },
    Rovs: {
        icon: 'btnenvenomedspear.png'
    },
    Rowd: {
        icon: 'btnwitchdoctoradept.png'
    },
    Rost: {
        icon: 'btnshamanadept.png'
    },
    Rosp: {
        icon: 'btnspikedbarricades.png'
    },
    Rotr: {
        icon: 'btnregenerate.png'
    },
    Rolf: {
        icon: 'btnliquidfire.png'
    },
    Roch: {
        icon: 'btnorcmeleeupone.png'
    },
    Rowt: {
        icon: 'btnspiritwalkeradepttraining.png'
    },
    Rorb: {
        icon: 'btnreinforcedburrows.png'
    },
    Robk: {
        icon: 'btnheadhunterberserker.png'
    },
    Ropm: {
        icon: 'btnpackbeast.png'
    },
    Robf: {
        icon: 'btnfirerocks.png'
    },
    Rume: {
        icon: 'btnunholystrength.png'
    },
    Rura: {
        icon: 'btncreatureattack.png'
    },
    Ruar: {
        icon: 'btnunholyarmor.png'
    },
    Ruac: {
        icon: 'btncannibalize.png'
    },
    Rugf: {
        icon: 'btnghoulfrenzy.png'
    },
    Ruwb: {
        icon: 'btnweb.png'
    },
    Rusf: {
        icon: 'btnstoneform.png'
    },
    Rune: {
        icon: 'btnnecromanceradept.png'
    },
    Ruba: {
        icon: 'btnbansheeadept.png'
    },
    Rufb: {
        icon: 'btnfreezingbreath.png'
    },
    Rusl: {
        icon: 'btnskeletallongevity.png'
    },
    Rucr: {
        icon: 'btncreaturecarapace.png'
    },
    Rupc: {
        icon: 'btnplaguecloud.png'
    },
    Rusm: {
        icon: 'btnskeletonmage.png'
    },
    Rubu: {
        icon: 'btncryptfiendburrow.png'
    },
    Rusp: {
        icon: 'btndestroyer.png'
    },
    Ruex: {
        icon: 'btnexhumecorpses.png'
    },
    Rupm: {
        icon: 'btnpackbeast.png'
    },
    Resm: {
        icon: 'btnstrengthofthemoon.png'
    },
    Resw: {
        icon: 'btnstrengthofthewild.png'
    },
    Rema: {
        icon: 'btnmoonarmor.png'
    },
    Rerh: {
        icon: 'btnreinforcedhides.png'
    },
    Reuv: {
        icon: 'btnultravision.png'
    },
    Renb: {
        icon: 'btnnaturesblessing.png'
    },
    Resc: {
        icon: 'btnsentinel.png'
    },
    Remg: {
        icon: 'btnupgrademoonglaive.png'
    },
    Reib: {
        icon: 'btnimprovedbows.png'
    },
    Remk: {
        icon: 'btnmarksmanship.png'
    },
    Redt: {
        icon: 'btndotadepttraining.png'
    },
    Redc: {
        icon: 'btndocadepttraining.png'
    },
    Resi: {
        icon: 'btndryaddispelmagic.png'
    },
    Recb: {
        icon: 'btncorrosivebreath.png'
    },
    Reht: {
        icon: 'btntamehippogriff.png'
    },
    Repb: {
        icon: 'btnvorpalblades.png'
    },
    Rers: {
        icon: 'btnresistantskin.png'
    },
    Rehs: {
        icon: 'btnhardenedskin.png'
    },
    Reeb: {
        icon: 'btnenchantedbears.png'
    },
    Reec: {
        icon: 'btnenchantedcrows.png'
    },
    Rews: {
        icon: 'btnwellspring.png'
    },
    Repm: {
        icon: 'btnpackbeast.png'
    },
    Rgfo: {
        icon: 'btnglyph.png'
    },
    Rguv: {
        icon: 'btnglyph.png'
    },
    Rnen: {
        icon: 'btnensnare.png'
    },
    Rnsw: {
        icon: 'btnsirenadept.png'
    },
    Rnsi: {
        icon: 'btndryaddispelmagic.png'
    },
    Rnat: {
        icon: 'btnnagaweaponup1.png'
    },
    Rnam: {
        icon: 'btnnagaarmorup1.png'
    },
    Rnsb: {
        icon: 'btnnagaburrow.png'
    }
};
