import { User, ISettingsState, EStateKeys, IStateWrapper, IPlayer, IPlayerStats, IMainAccount } from 'app/data/models';
export abstract class StateManagerInterface {
    public abstract getSettings(observerSettings?: boolean): Promise<ISettingsState>;
    public abstract getParseUser(): User;
    public abstract sendStateDiff(key: EStateKeys, differences: Array<any>);
    public abstract sendStateSnapshot(key: EStateKeys);
    public abstract sendAllStateSnapshots();
    public abstract getPlayerStats(player: IPlayer, realm: string): Promise<IPlayerStats>;
    public abstract getPlayerMainAccount(player: IPlayer, realm: string): Promise<IMainAccount>;

    public abstract onGameCreationStarted(rawGameData: any);
    public abstract onGameCreationFinished(state: IStateWrapper);
}
