import { environment } from '../../common-imports';
import { Injectable } from '@angular/core';
import { Parse } from '../../common-imports';
export { Parse };
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { ErrorService } from './error.service';

interface ISubscriptionDescriptor {
    subjects: Map<number, Subject<INextState<Parse.BaseObject>>>;
    nextSubscriptionId: number;
    state: Map<string, Parse.BaseObject>;
    subscription: any;
    creationPromise: Promise<Map<string, Parse.BaseObject>>;
}

export class Subscription<T extends Parse.BaseObject = Parse.BaseObject> {
    private _token: string;
    private _observable: Observable<INextState<T>>;
    private _state: Map<string, T>;
    private parseService: ParseService;
    constructor(token: string, observable: Observable<INextState<T>>, state: Map<string, T>, parseService: ParseService) {
        this._token = token;
        this._observable = observable;
        this._state = state;
        this.parseService = parseService;
    }

    public onNext(callback: (next: INextState<T>) => void): void {
        this.observable.subscribe(callback);
    }

    public unsubscribe() {
        if (this.token) {
            this.parseService.unsubscribe(this);
            delete this._token;
        }
    }

    public get state(): Map<string, T> {
        return this._state;
    }

    public set state(value: Map<string, T>) {
        this._state = value;
    }

    public get token(): string {
        return this._token;
    }

    private get observable(): Observable<INextState<T>> {
        return this._observable;
    }
}

export interface INextState<T extends Parse.BaseObject> {
    action: string;
    state: Map<string, T>;
    objectId: number;
}

@Injectable()
export class ParseService {
    private static isServer = false;
    private subscriptions = new Map<string, ISubscriptionDescriptor>();

    public static isParseServer(): boolean {
        return this.isServer;
    }

    public static setAsParseServer() {
        this.isServer = true;
    }

    constructor(private errorService: ErrorService) {
        // Initialize, if not a parse server
        if (!ParseService.isParseServer()) {

            // @ts-ignore
            if (environment.PARSE) {
                // @ts-ignore
                Parse.initialize(environment.PARSE.APP_ID, environment.PARSE.JS_KEY);
                // @ts-ignore
                (Parse as any).serverURL = environment.PARSE.URL;
            } else {
                // @ts-ignore
                Parse.initialize(environment.PARSE_APP_ID, environment.PARSE_JS_KEY, environment.PARSE_MASTER_KEY);
                // @ts-ignore
                (Parse as any).Parse.serverURL = environment.PARSE_URL;
                // @ts-ignore
                if (environment.PARSE_MASTER_KEY) {
                    Parse.Cloud.useMasterKey();
                }
            }
        } else {
            // @ts-ignore
            Parse.initialize(environment.APP_ID, environment.JS_KEY, environment.MASTER_KEY);
            // @ts-ignore
            (Parse as any).Parse.serverURL = process.env.SERVER_URL + process.env.PARSE_MOUNT;
        }
    }

    public fileToParse(file: File, fileName?: string): Parse.File {
        return new Parse.File((fileName) ? fileName : this.encodeFileName(file.name), file);
    }

    public subscribe<T extends Parse.BaseObject = Parse.BaseObject>(query: Parse.Query) {
        return new Promise<Subscription<T>>((resolve, reject) => {
            const queryId = this.getQueryId(query);
            const createSubscription = !this.subscriptions.has(queryId);
            if (!this.subscriptions.has(queryId)) {
                this.subscriptions.set(queryId, {
                    subjects: new Map(), nextSubscriptionId: 0, state: new Map(), subscription: null, creationPromise: new Promise<Map<string, Parse.BaseObject>>((resolveCreation, rejectCreation) => {
                        this.createSubscription(queryId, query).then(state => {
                            resolveCreation(state);
                        });
                    })
                });
            }

            const subObj = this.subscriptions.get(queryId);
            const subscriptionId = ++subObj.nextSubscriptionId;
            const subToken = queryId + ':' + subscriptionId;
            const subject = new Subject<INextState<T>>();
            subObj.subjects.set(subscriptionId, subject);

            subObj.creationPromise.then(state => {
                resolve(new Subscription<T>(subToken, subject.asObservable(), state as Map<string, T>, this));
            });
        });
    }

    public unsubscribe(subscription: Subscription) {
        const tokenParts = subscription.token.split(':');
        const queryId = tokenParts[0];
        const subscriptionId = parseInt(tokenParts[1], 10);

        const subObj = this.subscriptions.get(queryId);
        subObj.subjects.delete(subscriptionId);
        if (subObj.subjects.size <= 0) {
            if (subObj.subscription !== undefined) {
                subObj.subscription.unsubscribe();
            }
            this.subscriptions.delete(queryId);
        }
    }

    public patchSubclass(subclassObject: Parse.Object) {
        if (subclassObject === undefined || subclassObject == null) {
            return subclassObject;
        }
        const jsonObject = subclassObject.toJSON();
        jsonObject.className = subclassObject.className;
        return Parse.Object.fromJSON(jsonObject, true);
    }

    private createSubscription<T extends Parse.BaseObject = Parse.BaseObject>(queryID, query: Parse.Query) {
        return new Promise<Map<string, T>>((resolve, reject) => {
            query.find().then(async (objectList) => {
                if (this.subscriptions.has(queryID)) {
                    const subObj = this.subscriptions.get(queryID);
                    for (let indexOfObjects = 0; indexOfObjects < objectList.length; ++indexOfObjects) {
                        subObj.state.set(objectList[indexOfObjects].id, objectList[indexOfObjects]);
                    }
                    subObj.subscription = await query.subscribe();
                    resolve(subObj.state as Map<string, T>);

                    ['create', 'enter', 'update'].forEach((command) => {
                        subObj.subscription.on(command, (object) => {
                            const objectId = object.id;
                            subObj.state.set(objectId, this.patchSubclass(object));
                            for (const subject of Array.from(subObj.subjects.values())) {
                                subject.next({ action: command, state: subObj.state, objectId });
                            }
                        });
                    });

                    ['leave', 'delete'].forEach((command) => {
                        subObj.subscription.on(command, (object) => {
                            const objectId = object.id;
                            subObj.state.delete(objectId);
                            for (const subject of Array.from(subObj.subjects.values())) {
                                subject.next({ action: command, state: subObj.state, objectId });
                            }
                        });
                    });
                }
            }).catch(aError => {
                this.errorService.handleParseErrors(aError);
                reject(aError);
            });
        });
    }

    private getQueryId(query: Parse.Query): string {
        return query.className + '#' + JSON.stringify(query.toJSON()).replace(/[:\"]/g, '');
    }

    private encodeFileName(fileName: string): string {
        fileName = fileName.replace(new RegExp(/((?![a-zA-Z0-9-_\.\ ]).)/gm), '');
        return fileName.replace(' ', '_');
    }
}
